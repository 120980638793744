import {
  StyleSheet,
} from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: -25,
    backgroundColor: '#000',
  },
  content: {
    backgroundColor: '#f387c7',
  },
  drawerHeader: {
    padding: 0,
    margin: 0,
    backgroundColor: '#D26CA8',
    height: 90,
    top: 0,
    alignItems: 'center',
  },
  textMenu: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  textSubMenu: {
    color: '#ededed',
    fontSize: 16,
    
  },
});

export const navigationOptions = {
  headerStyle: {
    backgroundColor: '#0B58A8',
    
  },
  headerTintColor: '#fff',
  headerTitleStyle: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: 16,
    color: '#fff',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center'
  },
};
