import * as React from "react";
import { Body, Container, Icon, ListItem, Right, Text } from "native-base";
import config from "../../utils/config";
import Axios from "axios";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlatList } from "react-native-gesture-handler";
import PreLoader from "../../components/PreLoader";
import HandleHTTPError from "../../utils/handleErrors";
import { Platform, StyleSheet } from "react-native";

export default function SelectCapexAutoScreen(props) {
  const { navigation } = props;
  const [working, setWorking] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [refreshing, setRefreshing] = React.useState(false);
  const [next, setNext] = React.useState(null);
  const currentYear = new Date().getFullYear();
  const [params, setParams] = React.useState({
    isActive: "True",
    ordering: "-createdAt",
    pageSize: 50,
    page: 1,
    year: currentYear,
  });

  React.useEffect(() => {
    getCapex();
  }, [params]);

  const getCapex = () => {
    setWorking(true);
    const uri = `${config.api.host}projects/auto`;
    Axios.get(uri, {
      headers: config.api.headers,
      params,
    })
      .then(({ data }) => {
        const filteredData = data.results.filter(
          (el) => el.year === currentYear
        );
        setRefreshing(false);
        setNext(data.next);
        setDataList(filteredData);
        setWorking(false);
      })
      .catch((error) => {
        setRefreshing(false);
        HandleHTTPError(error, navigation);
        setWorking(false);
      });
  };

  if (working) {
    return <PreLoader />;
  }

  const renderListItem = (element) => {
    return (
      <ListItem
        disabled={element.is_unavailable}
        style={element.is_unavailable && { backgroundColor: "#b8bcc4" }}
        button
        onPress={() =>
          navigation.navigate("CreateAutoRequestScreen", {
            capex: element,
          })
        }
      >
        <Body>
          <Text>{element.name}</Text>
        </Body>
        <Right>
          <Icon name="md-arrow-forward" />
        </Right>
      </ListItem>
    );
  };

  const handleLoadMore = () => {
    if (next && !refreshing) {
      setParams({ ...params, ...{ page: params.page + 1, search } });
      // setRefreshing(true);
    }
  };

  if (working) {
    return <PreLoader />;
  }

  return (
    <Container style={styles.container}>
      <SafeAreaView style={styles.content}>
        <FlatList
          data={dataList}
          renderItem={({ item }) => renderListItem(item)}
          ListHeaderComponent={
            <ListItem>
              <Body>
                <Text>Seleccione AUTO</Text>
              </Body>
            </ListItem>
          }
          ListEmptyComponent={
            <ListItem>
              <Body>
                <Text>Sin elementos para mostrar</Text>
              </Body>
            </ListItem>
          }
          keyExtractor={(item) => item.id}
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.5}
        />
      </SafeAreaView>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 10,
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
    }),
  },
});
