import Axios from "axios";
import {
  Body,
  Button,
  Card,
  CardItem,
  Container,
  Form,
  Icon,
  Input,
  Item,
  Label,
  Picker,
  Text,
} from "native-base";
import * as React from "react";
import { Platform, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import PreLoader from "../../components/PreLoader";
import config from "../../utils/config";
import HandleHTTPError from "../../utils/handleErrors";

const numeral = require("numeral");

export default function BudgetLimitScreen({ navigation }) {
  const [working, setWorking] = React.useState(true);
  const [current_limit, setCurrentLimit] = React.useState({
    limit: {
      LIMIT_BUDGETED: [0, 0],
      LIMIT_NOT_BUDGETED: [0, 0],
    },
  });
  const [upper_limit_budgeted, setUpLimitB] = React.useState(null);
  const [low_limit_budgeted, setLowLimitB] = React.useState(null);
  const [low_limit_no_budgeted, setLowLimitNB] = React.useState(null);
  const [upper_limit_no_budgeted, setUpLimitNB] = React.useState(null);

  React.useEffect(() => {
    const uri = `${config.api.host}budgets`;
    setWorking(true);
    Axios.get(uri, {
      headers: config.api.headers,
    })
      .then(({ data }) => {
        setCurrentLimit(data.results[0]);

        setUpLimitB(data.results[0].limit?.LIMIT_BUDGETED[0]);
        setLowLimitB(data.results[0].limit?.LIMIT_BUDGETED[1]);
        setLowLimitNB(data.results[0].limit?.LIMIT_NOT_BUDGETED[0]);
        setUpLimitNB(data.results[0].limit?.LIMIT_NOT_BUDGETED[1]);

        setWorking(false);
      })
      .catch((error) => {
        HandleHTTPError(error, navigation);
        setWorking(false);
      });
  }, []);

  const makeRequest = () => {
    setWorking(true)
    const data = {
      limit: {
        LIMIT_BUDGETED: [
          parseFloat(low_limit_budgeted),
          parseFloat(upper_limit_budgeted),
        ],
        LIMIT_NOT_BUDGETED: [
          parseFloat(low_limit_no_budgeted),
          parseFloat(upper_limit_no_budgeted),
        ],
      },
    };
    console.log(data);
    const uri = `${config.api.host}budgets/${current_limit.id}`;
    Axios.patch(uri, data, {
      headers: config.api.headers,
    })
      .then(({ data }) => {
        setCurrentLimit(data);

        setUpLimitB(data.limit?.LIMIT_BUDGETED[0]);
        setLowLimitB(data.limit?.LIMIT_BUDGETED[1]);
        setLowLimitNB(data.limit?.LIMIT_NOT_BUDGETED[0]);
        setUpLimitNB(data.limit?.LIMIT_NOT_BUDGETED[1]);

        if (Platform.OS === "web") {
          alert(
            "Limites actualizados correctamente"
          );
        } else {
          Alert.alert(
            "Limites actualizados correctamente"
          );
        }

        setWorking(false);
      })
      .catch((error) => {
        console.log(error);
        HandleHTTPError(error, navigation);
        setWorking(false);
      });
  };

  const shouldBeDisabled = () => {
    if (
      upper_limit_budgeted &&
      low_limit_budgeted &&
      upper_limit_no_budgeted &&
      low_limit_no_budgeted
    ) {
      return false;
    } else {
      return true;
    }
  };

  if (working) {
    return <PreLoader />;
  }

  return (
    <Container style={styles.container}>
      <SafeAreaView style={styles.content}>
        <Card
          style={{ width: "50%", alignSelf: "center", borderWidth: 0 }}
          noShadow
        >
          <CardItem>
            <Body style={{}}>
              <Label>Limite actual presupuestado</Label>
              <Text note>
                Limite inferior:{" "}
                {numeral(current_limit.limit?.LIMIT_BUDGETED[0]).format(
                  "$ 000,000.00"
                )}
              </Text>
              <Text note>
                Limite superior:{" "}
                {numeral(current_limit.limit?.LIMIT_BUDGETED[1]).format(
                  "$ 000,000.00"
                )}
              </Text>
            </Body>
          </CardItem>
          <CardItem>
            <Body style={{}}>
              <Label>Limite actual no presupuestado</Label>
              <Text note>
                Limite inferior:{" "}
                {numeral(current_limit.limit?.LIMIT_NOT_BUDGETED[0]).format(
                  "$ 000,000.00"
                )}
              </Text>
              <Text note>
                Limite superior:{" "}
                {numeral(current_limit.limit?.LIMIT_NOT_BUDGETED[1]).format(
                  "$ 000,000.00"
                )}
              </Text>
            </Body>
          </CardItem>
        </Card>
        <Form style={{ width: "50%", alignSelf: "center" }}>
          <Label>Nuevo Limite presupuestado</Label>
          <Item stackedLabel bordered>
            <Label>Limite inferior</Label>
            <Input
              value={low_limit_budgeted}
              onChangeText={(text) => setLowLimitB(text)}
            />
          </Item>
          <Item stackedLabel bordered>
            <Label>Limite superior</Label>
            <Input
              value={upper_limit_budgeted}
              onChangeText={(text) => setUpLimitB(text)}
            />
          </Item>
        </Form>
        <Form style={{ width: "50%", alignSelf: "center" }}>
          <Label>Nuevo Limite no presupuestado</Label>
          <Item stackedLabel bordered>
            <Label>Limite inferior</Label>
            <Input
              value={low_limit_no_budgeted}
              onChangeText={(text) => setLowLimitNB(text)}
            />
          </Item>
          <Item stackedLabel bordered>
            <Label>Limite superior</Label>
            <Input
              value={upper_limit_no_budgeted}
              onChangeText={(text) => setUpLimitNB(text)}
            />
          </Item>
        </Form>
        <Button
          style={{ alignSelf: "center", marginTop: "5%" }}
          large
          info
          disabled={shouldBeDisabled()}
          onPress={() => makeRequest()}
        >
          <Icon name="paper-plane-outline" />
          <Text>Guardar</Text>
        </Button>
      </SafeAreaView>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 10,
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
    }),
  },
  saveButton: {
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
      marginTop: "10%",
    }),
  },
  body: {
    ...Platform.select({
      web: {
        // flexDirection: 'row',
        justifyContent: "space-evenly",
      },
      default: {
        flexDirection: "column",
      },
    }),
  },
});
