import { NavigationContainer, createNavigationContainerRef } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import LinkingConfiguration from './LinkingConfiguration';
import SignInScreen from '../screens/auth/SignInScreen';
import { LoggContext } from '../stores/Store';
import { navigationOptions } from '../constants/Navigation';
import { loggedValues } from '../utils/enums';
import BottomTabSheetsNavigator from './BottomTabSheetsNavigator';
import HomeStack, { AprovationsStack } from './StacksNavigator';
import SettingsScreen from '../screens/SettingsScreen';
import { createDrawerNavigator } from '@react-navigation/drawer';
import SideBar from '../screens/sideBar';
import HomeScreenAprovations from '../screens/aprovations';
import SideBarManager from '../screens/sideBarManager';
import AuthLoadingScreen from '../screens/AuthLoadingScreen';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const AppNavigator = () => {
  const [logged] = React.useContext(LoggContext);

  const LoggedNavigation = () => (
    <Drawer.Navigator
      drawerContent={(props) => <SideBar {...props} />}
      screenOptions={navigationOptions}
      initialRouteName="Root">
      {/* <Stack.Screen name="Root" component={BottomTabNavigator} /> */}
      <Drawer.Screen
        name="Root"
        component={HomeStack}
        options={{
          // headerTitle: props => <LogoTitle {...props} title={'Escritorio'} />,
          headerShown: false,
          title: 'CAPEX'

        }}
      />
    </Drawer.Navigator>
  )

  const LoggedManagerNavigation = () => (
    <Drawer.Navigator
      drawerContent={(props) => <SideBarManager {...props} />}
      screenOptions={navigationOptions}
      initialRouteName="RootManager">
      {/* <Stack.Screen name="Root" component={BottomTabNavigator} /> */}
      <Drawer.Screen
        name="RootManager"
        component={AprovationsStack}
        options={{
          // headerTitle: props => <LogoTitle {...props} title={'Escritorio'} />,
          headerShown: false,
          title: 'CAPEX'

        }}
      />
    </Drawer.Navigator>
  )

  const GuestNavigation = () => (
    <Stack.Navigator
      screenOptions={navigationOptions}
    >
      <Stack.Screen name="SignInScreen"
        component={SignInScreen}
        options={{
          headerShown: false,
          title: 'Login'
        }} />
    </Stack.Navigator>
  )


  return (
    <NavigationContainer >
      <Stack.Navigator initialRouteName="AuthLoading" screenOptions={{
        headerShown: false
      }} >
        <Stack.Screen name="AuthLoading" component={AuthLoadingScreen} />
        <Stack.Screen name="Guest" component={GuestNavigation} />
        <Stack.Screen name="Logged" component={LoggedNavigation} />
        <Stack.Screen name="LoggedManager" component={LoggedManagerNavigation} />
        {/* <Stack.Screen name="Auto" component={LoggedManagerNavigation} /> */}
      </Stack.Navigator>
    </NavigationContainer >
  );
}

export default AppNavigator;