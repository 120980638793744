import * as React from "react";
import {
  Body,
  Button,
  Container,
  Grid,
  Icon,
  Label,
  Left,
  ListItem,
  Right,
  Row,
  Tab,
  TabHeading,
  Tabs,
  Text,
  View,
} from "native-base";
import config from "../../utils/config";
import Axios from "axios";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlatList } from "react-native-gesture-handler";
import PreLoader from "../../components/PreLoader";
import HandleHTTPError from "../../utils/handleErrors";
import { Linking, Platform, StyleSheet, TextInput } from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import * as XLSX from "xlsx";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
var numeral = require("numeral");
import moment from "moment";
import DateTimePickerWeb from "./datepickerweb";

export default function ReportsScreen(props) {
  const { navigation } = props;
  const [working, setWorking] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [created_at__gte, setGTE] = React.useState(
    moment().year(moment().toDate().getFullYear()).startOf("year").toDate()
  ); // inicio
  const [created_at__lte, setLTE] = React.useState(moment().toDate()); // fin
  const [showGTE, setShowGTE] = React.useState(false);
  const [showLTE, setShowLTE] = React.useState(false);

  const getData = () => {
    setWorking(true);
    const uri = `${config.api.host}requisitions`;
    Axios.get(uri, {
      headers: config.api.headers,
      params: {
        created_at__gte,
        created_at__lte,
        offset: 0, //pagina
        limit: 500, // numero de resultados
      },
    })
      .then(({ data }) => {
        setDataList(data.results);
        getAutoRequisitions();
        setWorking(false);
      })
      .catch((error) => {
        if (Platform.OS === "web") {
          alert(`Ha ocurrido un error ${JSON.stringify(error.response)}`);
        } else {
          HandleHTTPError(error, navigation);
        }
        setWorking(false);
      });
  };

  const getAutoRequisitions = () => {
    setWorking(true);
    const uri = `${config.api.host}requisitions/auto`;
    Axios.get(uri, {
      headers: config.api.headers,
      params: {
        created_at__gte,
        created_at__lte,
      },
    })
      .then(({ data }) => {
        setDataList((prev) => [...prev, ...data.results]);
        setWorking(false);
      })
      .catch((error) => {
        if (Platform.OS === "web") {
          alert(`Ha ocurrido un error ${JSON.stringify(error.response)}`);
        } else {
          HandleHTTPError(error, navigation);
        }

        setWorking(false);
      });
  };

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      getData();
    });
    return unsubscribe;
  }, [search, navigation]);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleSubmit = () => {
    setSearch(searchTerm);
  };

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const returnKind = (kind) => {
    if (kind === "capex") {
      return "CAPEX";
    }
    if (kind === "sub") {
      return "Subdivisión";
    }
    if (kind === "change") {
      return "Cambio";
    }
    return "AUTO";
  };

  const returnStatus = (status) => {
    if (status === 0) {
      return "En proceso";
    }
    if (status === 1) {
      return "Aprobada";
    }
    if (status === 2) {
      return "Rechazada";
    }
    if (status === 3) {
      return "Cancelada";
    }
  };

  const formatJSON = () => {
    const result = [];
    dataList.map((element) => {
      const data = {
        Tipo: returnKind(element.kind),
        "Nombre Soliciud": element.name,
        Descripcion: element.description,
        Objetivo: element.objetive,
        Observaciones: element.observations,
        "Monto Solicitado": !element.kind
          ? element.qty_requested
          : element.budget_requested,
        "Tomado de": element.capex.name,
        "Recibido por": element.capex_receiver?.name || "",
        Solicitante: element.requested_by?.full_name || "",
        Fecha: new Date(element.created_at).toLocaleString(),
        Estatus: returnStatus(element.status),
      };
      result.push(data);
    });
    return result;
  };

  const exportReport = async () => {
    try {
      setWorking(true);
      const ws = XLSX.utils.json_to_sheet(formatJSON());
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Solicitudes");
      const wbout = XLSX.write(wb, {
        type: "base64",
        bookType: "xlsx",
      });
      if (Platform.OS === "web") {
        var blob = new Blob([s2ab(atob(wbout))], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });

        let link = document.createElement("a");
        link.download = `report-${moment().format("DD-MM-YYYY")}.xlsx`;
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.click();
      } else {
        const uri =
          FileSystem.cacheDirectory +
          `report-${moment().format("DD-MM-YYYY")}.xlsx`;
        await FileSystem.writeAsStringAsync(uri, wbout, {
          encoding: FileSystem.EncodingType.Base64,
        });

        await Sharing.shareAsync(uri, {
          mimeType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          dialogTitle: "Reporte",
          UTI: "com.microsoft.excel.xlsx",
        });
      }
    } catch (error) {
      setWorking(false);
      console.log(error);
    }
  };

  const renderStatus = (status) => {
    if (status === 0) {
      return <Text style={{ color: "#007bff" }}>En proceso</Text>;
    }
    if (status === 1) {
      return <Text style={{ color: "#28a745" }}>Aprobada</Text>;
    }
    if (status === 2) {
      return <Text style={{ color: "#dc3545" }}>Rechazada</Text>;
    }
    if (status === 3) {
      return <Text style={{ color: "#dc3545" }}>Cancelada</Text>;
    }
  };

  const renderKind = (kind) => {
    if (kind === "capex") {
      return <Text style={{ color: "#007bff" }}>CAPEX</Text>;
    }
    if (kind === "sub") {
      return <Text style={{ color: "#6c757d" }}>Subdivisión</Text>;
    }
    if (kind === "change") {
      return <Text style={{ color: "#28a745" }}>Cambio</Text>;
    }
    return <Text style={{ color: "#dc3545" }}>AUTO</Text>;
  };

  const getNextScreen = (kind) => {
    if (kind === "capex") {
      return "AproveCapexScreen";
    }
    if (kind === "sub") {
      return "AproveSubScreen";
    }
    if (kind === "change") {
      return "AproveCambioScreen";
    }
  };

  const renderNames = (element) => {
    if (element.kind === "change") {
      return (
        <>
          <Text>
            Emisor: {element.name} {renderKind(element.kind)}
          </Text>
          <Text>Receptor: {element.capex_receiver.name}</Text>
        </>
      );
    } else {
      return (
        <Text>
          {element.name} {renderKind(element.kind)}
        </Text>
      );
    }
  };

  const renderListItem = (element) => {
    if (Platform.OS === "web") {
      return (
        <ListItem
          button
          //  onPress={() => navigation.navigate(route, {
          //   item: element.id
          // })}
        >
          <Body>
            {renderNames(element)}
            <Text>Solicitante: {element.requested_by?.full_name}</Text>
            <Text>Descripción: {element.description}</Text>
            <Text>Objetivo: {element.objective}</Text>
          </Body>
          <View>
            <Text>Fecha: {new Date(element.created_at).toLocaleString()}</Text>
            <Text>
              Monto solicitado:
              <Text style={{ fontWeight: "bold", color: "green" }}>
                {numeral(
                  !element.kind
                    ? element.qty_requested
                    : element.budget_requested
                ).format("000,000.00")}
              </Text>
            </Text>
            {renderStatus(element.status)}
          </View>
        </ListItem>
      );
    }
    return (
      <ListItem button>
        <Body>
          <Text>
            {element.name} {renderKind(element.kind)}
          </Text>
          <Text>Fecha: {new Date(element.created_at).toLocaleString()}</Text>
          <Text>Solicitante: {element.requested_by?.full_name}</Text>
          <Text>
            Monto solicitado:
            <Text style={{ fontWeight: "bold", color: "green" }}>
              {numeral(
                !element.kind ? element.qty_requested : element.budget_requested
              ).format("000,000.00")}
            </Text>
          </Text>
          <Text>Descripción: {element.description}</Text>
          <Text>Objetivo: {element.objective}</Text>
          {renderStatus(element.status)}
        </Body>
      </ListItem>
    );
  };

  const setDate = (newDate) => {};

  const RenderDatePicker = ({ value, setShow, setter }) => {
    if (Platform.OS === "web") return null;
    return (
      <DateTimePicker
        style={{ width: 170 }}
        value={value}
        mode="date"
        placeholder="select date"
        format="DD-MM-YYYY"
        confirmBtnText="Ok"
        cancelBtnText="Cancelar"
        maximumDate={moment().toDate()}
        customStyles={{
          dateIcon: {
            position: "absolute",
            left: 0,
            top: 4,
            marginLeft: 0,
          },
          dateInput: {
            marginLeft: 36,
            borderWidth: 0,
          },
        }}
        onChange={({ nativeEvent, type }) => {
          setShow(false);
          if (type === "set") {
            setter(new Date(nativeEvent.timestamp));
          }
        }}
      />
    );
  };

  if (working) {
    return <PreLoader />;
  }

  return (
    <Container style={styles.container}>
      <SafeAreaView style={styles.content}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View>
            <ListItem
              onPress={() => setShowGTE(true)}
              regular
              style={{
                flexDirection: "column",
                borderColor: "transparent",
                justifyContent: "center",
              }}
            >
              <Text>Fecha inicio:</Text>
              <Text style={{ alignSelf: "flex-end" }}>
                <Icon name="calendar-outline" />{" "}
                {Platform.OS === "web" ? (
                  <DateTimePickerWeb
                    value={moment(created_at__gte).format("DD-MM-YYYY")}
                    maxvalue={moment(new Date()).format("DD-MM-YYYY")}
                    // onChange={({ nativeEvent, type }) => {
                    //     if (type === 'input') {
                    //         setGTE(new Date(nativeEvent.timeStamp));
                    //     }
                    // }}
                    onChange={(val) => {
                      console.log(val);
                      setGTE(new Date(val));
                    }}
                  />
                ) : (
                  moment(created_at__gte).format("DD-MM-YYYY")
                )}
              </Text>
              {showGTE && (
                <RenderDatePicker
                  value={created_at__gte}
                  setShow={setShowGTE}
                  setter={setGTE}
                />
              )}
            </ListItem>
          </View>
          <View>
            <ListItem
              onPress={() => setShowLTE(true)}
              regular
              style={{
                flexDirection: "column",
                borderColor: "transparent",
                justifyContent: "center",
              }}
            >
              <Text>Fecha fin:</Text>
              <Text style={{ alignSelf: "flex-end" }}>
                <Icon name="calendar-outline" />{" "}
                {Platform.OS === "web" ? (
                  <DateTimePickerWeb
                    value={moment(created_at__lte).format("DD-MM-YYYY")}
                    maxvalue={moment(new Date()).format("DD-MM-YYYY")}
                    // onChange={({ nativeEvent, type }) => {
                    //     if (type === 'input') {
                    //         console.log(nativeEvent);
                    //         setLTE(new Date(nativeEvent.timeStamp));
                    //     }
                    // }}
                    onChange={(val) => {
                      console.log(val);
                      setLTE(new Date(val));
                    }}
                  />
                ) : (
                  moment(created_at__lte).format("DD-MM-YYYY")
                )}
              </Text>
              {showLTE && (
                <RenderDatePicker
                  value={created_at__lte}
                  setShow={setShowLTE}
                  setter={setLTE}
                />
              )}
            </ListItem>
          </View>
          <View style={{ justifyContent: "center" }}>
            <Button onPress={() => getData()} info rounded>
              <Icon name="md-search" />
            </Button>
          </View>
          <View style={{ justifyContent: "center" }}>
            <Button
              iconRight
              success
              onPress={() => exportReport().then(() => setWorking(false))}
            >
              <Text>Exportar a XLSX</Text>
              <Icon name="md-share" />
            </Button>
          </View>
        </View>
        <FlatList
          data={dataList}
          renderItem={({ item }) => renderListItem(item)}
          ListEmptyComponent={
            <ListItem>
              <Body>
                <Text>Sin elementos para mostrar</Text>
              </Body>
            </ListItem>
          }
          keyExtractor={(item) => item.id}
        />
        <Button
          block
          iconRight
          success
          onPress={() => exportReport().then(() => setWorking(false))}
        >
          <Text>Exportar a XLSX</Text>
          <Icon name="md-share" />
        </Button>
      </SafeAreaView>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 10,
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
    }),
  },
  searchSection: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  searchIcon: {
    padding: 10,
  },
  input: {
    flex: 1,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    backgroundColor: "#fff",
    color: "#424242",
    borderWidth: 1,
    borderColor: "#eee",
  },
});
