import { Body, CardItem, Container, Icon, Label, List, ListItem, Right } from 'native-base';
import * as React from 'react';
import { Platform, StyleSheet, Text } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import PreLoader from '../components/PreLoader';
import { UserContext } from '../stores/Store.tsx';

var numeral = require('numeral');


export default function HomeScreen(props) {
  const { navigation } = props;
  const [user] = React.useContext(UserContext);
  const [active, setActive] = React.useState(false);
  const [working, setWorking] = React.useState(false);
  const isAutoRequester = user?.groups?.find((element) => element === 'SOLICITANTES DE AUTOS') || false;

  const renderListItem = (element, screen) => {
    return (
      <ListItem onPress={() => navigation.navigate(screen)}>
        <Body>
          <Text>{element}</Text>
        </Body>
        <Right>
          <Icon name='md-arrow-forward' />
        </Right>
      </ListItem>
    )
  }

  const renderRequestList = () => {
    if (isAutoRequester) {
      return (
        <List>
          {/* {renderListItem('Solicitud CAPEX', 'SelectCapexScreen')} */}
          {renderListItem('Solicitud Cambio', 'SelectCapexCambioScreen')}
          {/* {renderListItem('Solicitud Subdivisión', 'SelectCapexSubScreen')} */}
          {renderListItem('Solicitud Auto', 'SelectCapexAutoScreen')}
        </List>
      )
    } else {
      return (
      <List>
        {renderListItem('Solicitud CAPEX', 'SelectCapexScreen')}
        {renderListItem('Solicitud Cambio', 'SelectCapexCambioScreen')}
        {renderListItem('Solicitud Subdivisión', 'SelectCapexSubScreen')}
        {/* {isAutoRequester && renderListItem('Solicitud Auto', 'SelectCapexAutoScreen')} */}
      </List>
      )
    }
  }

  if (working) {
    return <PreLoader />;
  }

  return (
    <Container style={styles.container}>
      <SafeAreaView style={styles.content}>
        <CardItem header>
          <Label>
            <Text>Realizar solicitud</Text>
          </Label>
        </CardItem>
        {renderRequestList()}

      </SafeAreaView>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1, padding: 10,
    ...Platform.OS === 'web' && {
      width: '50%',
      alignSelf: 'center'
    }
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(0,0,0,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  },
  contentContainer: {
    paddingTop: 30,
  },
  welcomeContainer: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
    marginTop: 3,
    marginLeft: -10,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightText: {
    color: 'rgba(96,100,109, 0.8)',
  },
  codeHighlightContainer: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    lineHeight: 24,
    textAlign: 'center',
  },
  tabBarInfoContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    ...Platform.select({
      ios: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
      },
      android: {
        elevation: 20,
      },
    }),
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    paddingVertical: 20,
  },
  tabBarInfoText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    textAlign: 'center',
  },
  navigationFilename: {
    marginTop: 5,
  },
  helpContainer: {
    marginTop: 15,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
