import { createElement } from "react-native-web";

export default function DateTimePickerWeb({ value, maxvalue, onChange }) {
  
    return createElement('input', {
      type: 'date',
    //   value: value,
      max: maxvalue,
      onChange: (event) => onChange(event.target.value),
    })
  }