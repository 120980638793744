import React, { useState, createContext, useEffect } from 'react';
// import { AsyncStorage } from 'react-native';
import PropTypes from 'prop-types';
import Axios from 'axios';
import config from '../utils/config';
import PreLoader from '../components/PreLoader';
import Request from '../constants/Request';
import { loggedValues } from '../utils/enums';

export const CategoriesContext = createContext([]);
export const InfoContext = createContext([]);
export const BranchOfficesContext = createContext([]);
export const LoggContext = createContext(loggedValues.NOLOGGED);
export const OrderingContext = createContext('');
export const UserContext = createContext({});
export const PriceListContext = createContext({});
export const ItemsContext = createContext([]);

function Store({ children }) {
  const [logged, setLogged] = useState(loggedValues.NOLOGGED);
  const [working, setWorking] = useState(false);
  const [user, setUser] = useState({});
  const [items, setItems] = useState([]);
  const [priceList, setPriceList] = useState({});
  const [ordering, setOrdering] = useState('-createdAt');

  if (working) {
    return (
      <PreLoader />
    );
  }

  return (
    <LoggContext.Provider value={[logged, setLogged]} >
      {/** Provider suscribe a cambios del value a todos sus childrens,
        los children vuelven a renderizar cuando el value cambia* */}
      <UserContext.Provider value={[user, setUser]} >
        <PriceListContext.Provider value={[priceList, setPriceList]}>
          <ItemsContext.Provider value={[items, setItems]}>
            <OrderingContext.Provider value={[ordering, setOrdering]}>
              {children}
            </OrderingContext.Provider>
          </ItemsContext.Provider>
        </PriceListContext.Provider>
      </UserContext.Provider>
    </LoggContext.Provider>
  );
}
Store.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Store;
