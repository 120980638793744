import { Body, Card, CardItem, Container, Right, Text } from "native-base";
import * as React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import moment from "moment";
import { Platform, StyleSheet } from "react-native";
import { FlatList } from "react-native-gesture-handler";

export default function RequestDetailScreen({ route }) {
  const capex = route.params.item;
  const approvals = capex.approvals;

  const renderLastAprove = (element) => {
    if (element.rejected_by) {
      return <Text>No aprobada por: {element.approved_by.code} {element.rejected_by.full_name}</Text>;
    } else {
      if (element.approved_by) {
        return <Text>Aprobada por: {element.approved_by.code} {element.approved_by.full_name}</Text>;
      }
    }
    return <Text>Sin información para mostrar</Text>;
  };

  const renderItem = ({item}) => {
    return (
      <CardItem>
        <Body>
          {renderLastAprove(item)}
        </Body>
        
        <Right>
          <Text>
            Fecha: {new Date(item.created_at).toLocaleString()}
          </Text>
        </Right>
      </CardItem>
    );
  };

  return (
    <Container style={styles.container}>
      <SafeAreaView style={styles.content}>
        <Card>
         <CardItem>
           <Text>Historico de aprobaciones</Text>
           </CardItem>
          <FlatList data={approvals} renderItem={(item) => renderItem(item)} />
        </Card>
      </SafeAreaView>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 10,
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
    }),
  },
  searchSection: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  searchIcon: {
    padding: 10,
  },
  input: {
    flex: 1,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    backgroundColor: "#fff",
    color: "#424242",
    borderWidth: 1,
    borderColor: "#eee",
  },
});
