import React, { Component, useContext } from "react";
import {
  Text,
  Icon,
  ListItem,
  Content,
  Left,
  Body,
  Right,
  Badge,
  Button,
  Header,
  Container,
  Title,
  View,
} from "native-base";
import styles from "./style";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import { UserContext } from "../../stores/Store";
import Colors from "../../constants/Colors";

const logo = require("../../assets/images/icon2.png");

function SideBar(props) {
  const { navigation } = props;
  const [user] = useContext(UserContext);
  const isAutoRequester =
    user?.groups?.find((element) => element === "SOLICITANTES DE AUTOS") ||
    false;
  const isCompras =
    user?.groups?.find((element) => element === "COMPRAS") || false;
  return (
    <DrawerContentScrollView
      style={styles.container}
      forceInset={{ top: "always", horizontal: "never" }}
    >
      <Content style={{ backgroundColor: "#fff" }}>
        <UserContext.Consumer>
          {(user) => (
            <ListItem
              button
              onPress={() => {
                console.log(user[0]?.email);
              }}
              icon
              style={styles.links}
            >
              <Icon
                style={{ color: "#ffa726", fontSize: 18, marginRight: 5 }}
                active
                name="person"
              />
              <Text
                style={{
                  color: "#555",
                  // fontSize: 18,
                }}
              >
                {user[0]?.email}
              </Text>
            </ListItem>
          )}
        </UserContext.Consumer>
        <ListItem
          button
          onPress={() => {
            navigation.navigate("HomeScreen");
          }}
          icon
          style={styles.links}
        >
          {/* <Left> */}
          <Icon active name="md-home" style={{ color: "#ffa726" }} />
          {/* </Left> */}
          <Body style={{ borderColor: "transparent" }}>
            <Text style={{ color: "#555", marginLeft: 15 }}>
              Realizar solicitudes
            </Text>
          </Body>
        </ListItem>
        <ListItem
          button
          onPress={() => {
            navigation.navigate("RequestListScreen");
          }}
          icon
          style={styles.links}
        >
          {/* <Left> */}
          <Icon active name="md-stats-chart" style={{ color: "#ffa726" }} />
          {/* </Left> */}
          <Body style={{ borderColor: "transparent" }}>
            <Text style={{ color: "#555", marginLeft: 15 }}>
              Mis estatus de solicitudes
            </Text>
          </Body>
        </ListItem>
        {(isAutoRequester || isCompras) && (
          <ListItem
            button
            onPress={() => {
              navigation.navigate("RequestListAutoScreen");
            }}
            icon
            style={styles.links}
          >
            <Icon active name="md-car" style={{ color: "#ffa726" }} />
            <Body style={{ borderColor: "transparent" }}>
              <Text style={{ color: "#555", marginLeft: 15 }}>
                Mis estatus de solicitudes AUTO
              </Text>
            </Body>
          </ListItem>
        )}

        <ListItem
          button
          onPress={() => {
            navigation.navigate("SettingsScreen");
          }}
          icon
          style={styles.links}
        >
          {/* <Left> */}
          <Icon active name="settings" style={{ color: "#ffa726" }} />
          {/* </Left> */}
          <Body style={{ borderColor: "transparent" }}>
            <Text style={{ color: "#555", marginLeft: 15 }}>
              Configuraciones
            </Text>
          </Body>
        </ListItem>
      </Content>
    </DrawerContentScrollView>
  );
}

export default SideBar;
