import { StackActions } from '@react-navigation/routers';
import * as Updates from 'expo-updates';
import * as React from 'react';
import { Platform } from 'react-native';
import PreLoader from '../components/PreLoader';
import { LoggContext, UserContext } from '../stores/Store';

export default function LogoutScreen(props) {
    const { navigation } = props;
    const [, setLogged] = React.useContext(LoggContext);
    const [, setUser] = React.useContext(UserContext);

    React.useEffect(() => {
        setUser(null);
        setLogged(false);
        const resetAction = StackActions.replace('Guest', {

        });
        navigation.dispatch(resetAction);
        // if (!__DEV__) {
        //     reloadAsync()
        // }
        // if (Platform.OS === 'web') {
        //     const resetAction = StackActions.replace('Guest', {

        //     });
        //     navigation.dispatch(resetAction);
        // } else {
        //     Updates.reloadAsync()
        // }
    }, []);

    return <PreLoader />;
}