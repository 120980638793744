import * as React from "react";
import {
  Body,
  Button,
  CardItem,
  Container,
  Form,
  Icon,
  Input,
  Item,
  Label,
  ListItem,
  Picker,
  Right,
  Text,
  Textarea,
  View,
} from "native-base";
import config from "../../utils/config";
import Axios from "axios";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import PreLoader from "../../components/PreLoader";
import {
  Alert,
  Dimensions,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native";
import HandleHTTPError from "../../utils/handleErrors";
import { UserContext } from "../../stores/Store";
import { Tooltip } from "react-native-elements/dist/tooltip/Tooltip";

var numeral = require("numeral");
const { width, height } = Dimensions.get("window");

export default function SelectCapexCambioScreen(props) {
  const { navigation } = props;
  const [user] = React.useContext(UserContext);
  const [working, setWorking] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [dataForm, setDataForm] = React.useState({
    description: null,
    objective: null,
    budget_requested: 0,
  });
  const [capex, setCapex] = React.useState(null);
  const [capexData, setCapexData] = React.useState(null);
  const [capexDataReceiver, setCapexDataReceiver] = React.useState(null);
  const [capex_receiver, setCapexReceiver] = React.useState(null);
  const [availableBudget, setAvailableBudget] = React.useState(0);
  const [usedBudget, setUsedBudget] = React.useState(0);
  const [usedBudgetReceiver, setUsedBudgetReceiver] = React.useState(0);
  const [availableBudgetAtEnd, setAvailableBudgetAtEnd] = React.useState(0);
  const [availableBudgetReceiver, setAvailableBudgetReceiver] =
    React.useState(0);
  const currentYear = new Date().getFullYear();
  React.useEffect(() => {
    setWorking(true);
    const isAuto = user.groups.filter(
      (element) => element === "SOLICITANTES DE AUTOS"
    );
    const uri =
      isAuto.length > 0
        ? `${config.api.host}projects/auto`
        : `${config.api.host}projects/capex`;
    Axios.get(uri, {
      headers: config.api.headers,
      params: { year: currentYear },
    })
      .then(({ data }) => {
        const filteredData = data.results.filter(
          (el) => el.year === currentYear
        );
        setDataList(filteredData);
        setWorking(false);
      })
      .catch((error) => {
        HandleHTTPError(error, navigation);
        setWorking(false);
      });
  }, []);

  React.useEffect(() => {
    if (capexData && capexDataReceiver) {
      calculateAvailableEnd(dataForm.budget_requested || 0);
    }
  }, [capexDataReceiver, capexData]);

  function onValueChange(value: string, index: Number) {
    // const data = dataList.find((element) => element.id === value)
    // if (value.is_unavailable) {
    //   setCapex(null);
    // }
    const data = dataList[index - 1];
    handleChange({ budget_requested: 0 });
    setCapex(value);

    if (data) {
      setAvailableBudget(parseFloat(data.current_budget));
      setUsedBudget(parseFloat(data.budget) - parseFloat(data.current_budget));
      setCapexData(data);
    } else {
      setAvailableBudget(0);
      calculateAvailable(0);
    }
  }

  function onValueChangeReceiver(value: string) {
    // if (value.is_unavailable) {
    //   setCapexReceiver(null);
    // }
    handleChange({ budget_requested: 0 });
    const data = dataList.find((element) => element.id === value);
    setCapexReceiver(value);
    if (data) {
      setAvailableBudgetReceiver(parseFloat(data.current_budget));
      setUsedBudgetReceiver(
        parseFloat(data.budget) - parseFloat(data.current_budget)
      );
      setCapexDataReceiver(data);
      setAvailableBudgetAtEnd(parseFloat(data.current_budget));
      // if (capexData && data) {
      //     calculateAvailable(dataForm.budget_requested)
      // }
    } else {
      setAvailableBudgetReceiver(0);
      setAvailableBudgetAtEnd(0);
    }
  }

  const handleChange = (val) => {
    const data = { ...dataForm, ...val };
    setDataForm(data);
  };

  const calculateAvailable = (val) => {
    const qty = parseInt(val);
    if (isNaN(qty)) {
      console.log("NaN");
      const current_budget = parseFloat(capexData.current_budget);
      const budget = parseFloat(capexData.budget);
      //emisor
      setAvailableBudget(current_budget);
      setUsedBudget(parseFloat(budget) - parseFloat(current_budget));
      calculateAvailableEnd(0);
    } else {
      const current_budget = parseFloat(capexData.current_budget);
      const budget = parseFloat(capexData.budget);
      const available = current_budget - qty;
      const used = parseFloat(budget) - parseFloat(current_budget) + qty;
      if (available < 0) {
        Alert.alert("Error", "No tienes suficiente presupuesto disponible");
        handleChange({ budget_requested: 0 });
      } else {
        setAvailableBudget(available);
        setUsedBudget(used);
        calculateAvailableEnd(qty);
      }
    }
  };

  const calculateAvailableEnd = (val) => {
    const qty = parseInt(val);
    if (capexDataReceiver) {
      if (isNaN(qty)) {
        const available = 0;
        //receptor
        setAvailableBudgetAtEnd(capexDataReceiver.current_budget);
      } else {
        const available = parseFloat(capexDataReceiver.current_budget) + qty;
        setAvailableBudgetAtEnd(available);
      }
    }
  };

  const makeRequest = (data) => {
    const uri = `${config.api.host}requisitions/change`;
    setWorking(true);
    if (data.budget_requested < 0) {
      if (Platform.OS === "web") {
        alert("La cantidad solicitada no debe ser menor a 0");
      } else {
        Alert.alert("La cantidad solicitada no debe ser menor a 0");
      }
      setWorking(false);
      return;
    }
    Axios.post(uri, data, {
      headers: config.api.headers,
    })
      .then((response) => {
        setWorking(false);
        if (Platform.OS === "web") {
          alert(
            "Solicitud enviada correctamente",
            " La solicitud entró en proceso de aprobación"
          );
        } else {
          Alert.alert(
            "Solicitud enviada correctamente",
            " La solicitud entró en proceso de aprobación"
          );
        }

        navigation.navigate("HomeScreen");
      })
      .catch((error) => {
        HandleHTTPError(error, navigation);
        setWorking(false);
      });
  };

  if (working) {
    return <PreLoader />;
  }

  const pickerItems = dataList.map((element) => {
    return (
      <Picker.Item label={element.name} value={element.id} key={element.id} />
    );
  });

  const pickerItemsReciver = dataList.map((element) => {
    if (capex) {
      if (capex === element.id) return null;
    }
    return (
      <Picker.Item label={element.name} value={element.id} key={element.id} />
    );
  });

  if (Platform.OS === "web") {
    return (
      <Container style={styles.container}>
        <ScrollView style={styles.content}>
          <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }}>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <View style={{ justifyContent: "space-around" }}>
                <View style={styles.body}>
                  <Form>
                    <View>
                      <CardItem>
                        <Text style={{ color: "#dd1d21" }}>Emisor</Text>
                      </CardItem>
                    </View>

                    <Item>
                      <Label>CAPEX: </Label>
                      <Picker
                        note
                        mode="dropdown"
                        style={{ width: "48%", height: 50, marginRight: 5 }}
                        selectedValue={capex}
                        onValueChange={onValueChange.bind(this)}
                      >
                        <Picker.Item
                          label="Selecciona una opción"
                          value={null}
                        />
                        {pickerItems}
                      </Picker>
                      <Tooltip
                        skipAndroidStatusBar
                        height={250}
                        backgroundColor={"#fff"}
                        popover={
                          <Text>
                            Si un proyecto CAPEX no aparece en la lista es
                            debido a que tiene una solicitud en proceso.
                          </Text>
                        }
                      >
                        <Icon
                          style={{ fontSize: 20 }}
                          name="ios-information-circle-outline"
                        />
                      </Tooltip>
                    </Item>
                    {capex && (
                      <>
                        <CardItem
                          style={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Text>
                            {`Presupuesto Original: `}
                            <Text style={{ color: "#94bf8d" }}>
                              {numeral(capexData.budget).format("000,000.00")}
                            </Text>
                          </Text>
                        </CardItem>
                        <CardItem
                          style={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Text>
                            {`Presupuesto Usado: `}
                            <Text style={{ color: "#ed6105" }}>
                              {numeral(usedBudget).format("000,000.00")}
                            </Text>
                          </Text>
                        </CardItem>
                        <CardItem
                          style={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Text>
                            {`Presupuesto Disponible: `}
                            <Text style={{ color: "#94bf8d" }}>
                              {numeral(availableBudget).format("000,000.00")}
                            </Text>
                          </Text>
                        </CardItem>
                      </>
                    )}
                  </Form>
                  {capex && (
                    <Form>
                      <View>
                        <CardItem>
                          <Text style={{ color: "#dd1d21" }}>Receptor</Text>
                        </CardItem>
                      </View>

                      <Item>
                        <Label>CAPEX: </Label>
                        <Picker
                          note
                          mode="dropdown"
                          style={{ width: "50%", height: 50, marginRight: 5 }}
                          selectedValue={capex_receiver}
                          onValueChange={onValueChangeReceiver.bind(this)}
                        >
                          <Picker.Item
                            label="Selecciona una opción"
                            value={null}
                          />
                          {pickerItemsReciver}
                        </Picker>
                        <Tooltip
                          skipAndroidStatusBar
                          height={250}
                          backgroundColor={"#fff"}
                          popover={
                            <Text>
                              Si un proyecto CAPEX no aparece en la lista es
                              debido a que tiene una solicitud en proceso.
                            </Text>
                          }
                        >
                          <Icon
                            style={{ fontSize: 20 }}
                            name="ios-information-circle-outline"
                          />
                        </Tooltip>
                      </Item>
                      {capex_receiver && (
                        <View>
                          <CardItem
                            style={{
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Text>
                              {`Presupuesto Original: `}
                              <Text style={{ color: "#94bf8d" }}>
                                {numeral(capexDataReceiver.budget).format(
                                  "000,000.00"
                                )}
                              </Text>
                            </Text>
                          </CardItem>
                          <CardItem
                            style={{
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Text>
                              {`Presupuesto Usado: `}
                              <Text style={{ color: "#ed6105" }}>
                                {numeral(usedBudgetReceiver).format(
                                  "000,000.00"
                                )}
                              </Text>
                            </Text>
                          </CardItem>
                          <CardItem
                            style={{
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Text>
                              {`Presupuesto Disponible: `}
                              <Text style={{ color: "#94bf8d" }}>
                                {numeral(
                                  capexDataReceiver.current_budget
                                ).format("000,000.00")}
                              </Text>
                            </Text>
                          </CardItem>
                          <CardItem
                            style={{
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Text>
                              {`Presupuesto disponible al finalizar la aprobación: `}
                              <Text style={{ color: "#94bf8d" }}>
                                {numeral(availableBudgetAtEnd).format(
                                  "000,000.00"
                                )}
                              </Text>
                            </Text>
                          </CardItem>
                        </View>
                      )}
                    </Form>
                  )}
                </View>
                {capex_receiver && capex && (
                  <Form>
                    <Item bordered stackedLabel>
                      <Label>Cantidad Solicitada: </Label>
                      <Input
                        placeholder=""
                        keyboardType="number-pad"
                        onChangeText={(text) =>
                          handleChange({ budget_requested: text })
                        }
                        onSubmitEditing={() =>
                          calculateAvailable(dataForm.budget_requested)
                        }
                        onKeyPress={({ nativeEvent }) => {
                          if (nativeEvent.key === "Tab") {
                            calculateAvailable(dataForm.budget_requested);
                          }
                        }}
                      />
                    </Item>

                    <Item bordered stackedLabel>
                      <Label>Descripción de cambio: </Label>
                      <Input
                        placeholder=""
                        onFocus={() =>
                          calculateAvailable(dataForm.budget_requested)
                        }
                        defaultValue={dataForm.description}
                        onChangeText={(text) =>
                          handleChange({ description: text })
                        }
                      />
                    </Item>

                    <Item bordered stackedLabel>
                      <Label>Razones o beneficios de cambio: </Label>
                      <Input
                        // multiline
                        defaultValue={dataForm.objective}
                        onChangeText={(text) =>
                          handleChange({ objective: text })
                        }
                      />
                    </Item>
                  </Form>
                )}
              </View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
          {capex && capex_receiver && (
            <Button
              info
              disabled={
                !(
                  capex &&
                  capex_receiver &&
                  dataForm.budget_requested > 0 &&
                  dataForm.objective &&
                  dataForm.description
                )
              }
              large
              style={styles.saveButton}
              onPress={() => {
                const body = {
                  capex,
                  capex_receiver,
                  budget_requested: dataForm.budget_requested,
                  objective: dataForm.objective,
                  description: dataForm.description,
                };
                makeRequest(body);
              }}
            >
              <Icon name="paper-plane-outline" />
              <Text>Continuar</Text>
            </Button>
          )}
        </ScrollView>
      </Container>
    );
  }

  return (
    <Container style={styles.container}>
      <ScrollView style={styles.content}>
        <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }}>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <View style={{ justifyContent: "space-around" }}>
              <View style={styles.body}>
                <Form>
                  <View>
                    <CardItem>
                      <Text style={{ color: "#dd1d21" }}>Emisor</Text>
                    </CardItem>
                  </View>

                  <Item>
                    <Label>CAPEX: </Label>
                    <Picker
                      note
                      mode="dropdown"
                      style={{ width: "80%", height: 50, marginRight: 5 }}
                      selectedValue={capex}
                      onValueChange={onValueChange.bind(this)}
                    >
                      <Picker.Item label="Selecciona una opción" value={null} />
                      {pickerItems}
                    </Picker>
                    <Tooltip
                      skipAndroidStatusBar
                      height={250}
                      backgroundColor={"#fff"}
                      popover={
                        <Text>
                          Si un proyecto CAPEX no aparece en la lista es debido
                          a que tiene una solicitud en proceso.
                        </Text>
                      }
                    >
                      <Icon
                        style={{ fontSize: 20 }}
                        name="ios-information-circle-outline"
                      />
                    </Tooltip>
                  </Item>
                  {capex && (
                    <>
                      <CardItem
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Text>
                          {`Presupuesto Original: `}
                          <Text style={{ color: "#94bf8d" }}>
                            {numeral(capexData.budget).format("000,000.00")}
                          </Text>
                        </Text>
                      </CardItem>
                      <CardItem
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Text>
                          {`Presupuesto Usado: `}
                          <Text style={{ color: "#ed6105" }}>
                            {numeral(usedBudget).format("000,000.00")}
                          </Text>
                        </Text>
                      </CardItem>
                      <CardItem
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Text>
                          {`Presupuesto Disponible: `}
                          <Text style={{ color: "#94bf8d" }}>
                            {numeral(availableBudget).format("000,000.00")}
                          </Text>
                        </Text>
                      </CardItem>
                    </>
                  )}
                </Form>
                {capex && (
                  <Form>
                    <View>
                      <CardItem>
                        <Text style={{ color: "#dd1d21" }}>Receptor</Text>
                      </CardItem>
                    </View>

                    <Item>
                      <Label>CAPEX: </Label>
                      <Picker
                        note
                        mode="dropdown"
                        style={{ width: "80%", height: 50, marginRight: 5 }}
                        selectedValue={capex_receiver}
                        onValueChange={onValueChangeReceiver.bind(this)}
                      >
                        <Picker.Item
                          label="Selecciona una opción"
                          value={null}
                        />
                        {pickerItemsReciver}
                      </Picker>
                      <Tooltip
                        skipAndroidStatusBar
                        height={250}
                        backgroundColor={"#fff"}
                        popover={
                          <Text>
                            Si un proyecto CAPEX no aparece en la lista es
                            debido a que tiene una solicitud en proceso.
                          </Text>
                        }
                      >
                        <Icon
                          style={{ fontSize: 20 }}
                          name="ios-information-circle-outline"
                        />
                      </Tooltip>
                    </Item>
                    {capex_receiver && (
                      <View>
                        <CardItem
                          style={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Text>
                            {`Presupuesto Original: `}
                            <Text style={{ color: "#94bf8d" }}>
                              {numeral(capexDataReceiver.budget).format(
                                "000,000.00"
                              )}
                            </Text>
                          </Text>
                        </CardItem>
                        <CardItem
                          style={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Text>
                            {`Presupuesto Usado: `}
                            <Text style={{ color: "#ed6105" }}>
                              {numeral(usedBudgetReceiver).format("000,000.00")}
                            </Text>
                          </Text>
                        </CardItem>
                        <CardItem
                          style={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Text>
                            {`Presupuesto Disponible: `}
                            <Text style={{ color: "#94bf8d" }}>
                              {numeral(capexDataReceiver.current_budget).format(
                                "000,000.00"
                              )}
                            </Text>
                          </Text>
                        </CardItem>
                        <CardItem
                          style={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Text>
                            {`Presupuesto disponible al finalizar la aprobación: `}
                            <Text style={{ color: "#94bf8d" }}>
                              {numeral(availableBudgetAtEnd).format(
                                "000,000.00"
                              )}
                            </Text>
                          </Text>
                        </CardItem>
                      </View>
                    )}
                  </Form>
                )}
              </View>
              {capex_receiver && capex && (
                <Form>
                  <Item>
                    <Label>Cantidad Solicitada</Label>
                    <Input
                      placeholder=""
                      keyboardType="number-pad"
                      defaultValue={dataForm.budget_requested}
                      onChangeText={(text) =>
                        handleChange({ budget_requested: text })
                      }
                      onEndEditing={() =>
                        calculateAvailable(dataForm.budget_requested)
                      }
                    />
                  </Item>
                  <Item>
                    <Label>Descripción de cambio</Label>
                    <Input
                      placeholder=""
                      defaultValue={dataForm.description}
                      onChangeText={(text) =>
                        handleChange({ description: text })
                      }
                    />
                  </Item>
                  {/* <Item> */}
                  {/* <Label></Label> */}
                  <Textarea
                    rowSpan={5}
                    style={{ width: "90%", margin: 10 }}
                    placeholder="Razones o beneficios de cambio"
                    defaultValue={dataForm.objective}
                    onChangeText={(text) => handleChange({ objective: text })}
                  />
                  {/* </Item> */}
                </Form>
              )}
            </View>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      </ScrollView>
      <Button
        info
        disabled={
          !(
            capex &&
            capex_receiver &&
            dataForm.budget_requested > 0 &&
            dataForm.objective &&
            dataForm.description
          )
        }
        full
        style={styles.saveButton}
        onPress={() => {
          const body = {
            capex,
            capex_receiver,
            budget_requested: dataForm.budget_requested,
            objective: dataForm.objective,
            description: dataForm.description,
          };
          makeRequest(body);
        }}
      >
        <Text>Continuar</Text>
      </Button>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 10,
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
    }),
  },
  saveButton: {
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
      marginTop: "10%",
    }),
  },
  body: {
    ...Platform.select({
      web: {
        // flexDirection: 'row',
        justifyContent: "space-evenly",
      },
      default: {
        flexDirection: "column",
      },
    }),
  },
});
