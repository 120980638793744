import Axios from "axios";
import {
  Button,
  Card,
  CardItem,
  Container,
  Form,
  Icon,
  Input,
  Item,
  Label,
  Picker,
  Right,
  Switch,
  Text,
  View,
} from "native-base";
import * as React from "react";
import {
  Alert,
  Dimensions,
  KeyboardAvoidingView,
  Modal,
  Platform,
  StyleSheet,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";
import PreLoader from "../../components/PreLoader";
import { LoggContext } from "../../stores/Store";
import { hasNulls } from "../../utils/checkNulls";
import config from "../../utils/config";
import { loggedValues } from "../../utils/enums";
import HandleHTTPError from "../../utils/handleErrors";

var numeral = require("numeral");
const { width, height } = Dimensions.get("window");

export default function AproveAutoScreen(props) {
  const { navigation, route } = props;
  const [working, setWorking] = React.useState(true);
  const [logged] = React.useContext(LoggContext);
  const capex = route.params?.item;
  const [data, setData] = React.useState({});
  const [modalVisible, setModalVisible] = React.useState(false);
  const [dataForm, setDataForm] = React.useState({
    lease: null,
    type: null,
    period_deprecation: "1",
    monthly_cost: null,
    interest: null,
    purchase_condition: null,
    reviewer: null,
  });
  const [partners, setPartners] = React.useState([]);
  let reasons = "";

  React.useEffect(() => {
    const uri = `${config.api.host}requisitions/auto/${capex}`;
    setWorking(true);
    Axios.get(uri, {
      headers: config.api.headers,
    })
      .then(({ data }) => {
        setData(data);

        setWorking(false);
      })
      .catch((error) => {
        if (Platform.OS === "web") {
          alert(`Ha ocurrido un error ${JSON.stringify(error)}`);
        } else {
          HandleHTTPError(error, navigation);
        }
        setWorking(false);
      });
    if (logged === loggedValues.COMPRAS) {
      getBP();
    }
  }, []);

  const getBP = () => {
    const uri = `${config.api.host}partners/`;
    setWorking(true);
    Axios.get(uri, {
      headers: config.api.headers,
    })
      .then(({ data }) => {
        setPartners(data.results);
        setWorking(false);
      })
      .catch((error) => {
        if (Platform.OS === "web") {
          alert(`Ha ocurrido un error ${JSON.stringify(error)}`);
        } else {
          HandleHTTPError(error, navigation);
        }
        setWorking(false);
      });
  };

  const handleChange = (val) => {
    const data = { ...dataForm, ...val };
    setDataForm(data);
  };

  const changeRequestStatus = (status) => {
    setWorking(true);
    const msg =
      status === 1
        ? "La solicitud fue aprobada"
        : "La solicitud fue desaprobada";

    const uri = `${config.api.host}requisitions/auto/${capex}`;

    if (logged === loggedValues.COMPRAS && status !== 2) {
      const body = dataForm;
      body.status = status;
      if (status === 2) {
        body.observations = reasons;
      }
      body.period_deprecation = `${dataForm.period_deprecation} años`;
      Axios.patch(uri, body, {
        headers: config.api.headers,
      })
        .then(({ data }) => {
          setWorking(false);
          if (Platform.OS === "web") {
            alert("Se cambió el estado de la solicitud", msg);
          } else {
            Alert.alert("Se cambió el estado de la solicitud", msg);
          }

          navigation.navigate("HomeScreen");
        })
        .catch((error) => {
          if (Platform.OS === "web") {
            alert(`Ha ocurrido un error ${JSON.stringify(error.response)}`);
          } else {
            HandleHTTPError(error, navigation);
          }
          setWorking(false);
        });
    } else {
      Axios.patch(
        uri,
        { status },
        {
          headers: config.api.headers,
        }
      )
        .then(({ data }) => {
          setWorking(false);
          if (Platform.OS === "web") {
            alert("Se cambió el estado de la solicitud", msg);
          } else {
            Alert.alert("Se cambió el estado de la solicitud", msg);
          }
          navigation.navigate("HomeScreen");
        })
        .catch((error) => {
          if (Platform.OS === "web") {
            alert(`Ha ocurrido un error ${JSON.stringify(error.response)}`);
          } else {
            HandleHTTPError(error, navigation);
          }
          setWorking(false);
        });
    }
  };

  if (working) {
    return <PreLoader />;
  }

  if (!data) {
    return (
      <Container style={styles.container}>
        <SafeAreaView style={styles.content}>
          <Card>
            <CardItem>
              <View>
                <Text numberOfLines={2}>
                  Ha ocurrido un error al obtener la solicitud
                </Text>
              </View>
            </CardItem>
          </Card>
        </SafeAreaView>
      </Container>
    );
  }

  const RenderModal = () => {
    if (Platform.OS === "web") {
      return (
        // <BlurView
        //   intensity={80}
        //   tint="dark"
        //   style={[
        //     StyleSheet.absoluteFill,
        //     {
        //       flex: 1,
        //       // flexDirection: 'column',
        //       height,
        //       width,
        //       backgroundColor: "#000",
        //       zIndex: 9999,
        //     },
        //   ]}
        // >
        <Modal
          animationType={"slide"}
          transparent={false}
          visible={modalVisible}
          onRequestClose={() => setModalVisible(false)}
        >
          <Icon
            style={{ color: "white" }}
            onPress={() => setModalVisible(!modalVisible)}
            name="close"
          />
          <View
            style={{
              flex: 1,
              alignSelf: "center",
              paddingLeft: "40%",
              paddingRight: "40%",
              // justifyContent:'center'
            }}
          >
            <View
              style={{
                backgroundColor: "white",
                borderRadius: 20,
                padding: 35,
                alignItems: "center",
                alignSelf: "center",
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 4,
                elevation: 5,
              }}
            >
              <Form style={{ width: "100%", height: 100 }}>
                <Item stackedLabel bordered>
                  <Label>Escribe los motivos de desaprobación</Label>
                  <Input
                  // onChangeText={(text) => (reasons = text)}
                  />
                </Item>
              </Form>
              <Button
                danger
                style={{ alignSelf: "center" }}
                onPress={() => {
                  setModalVisible(!modalVisible);
                  changeRequestStatus(2);
                }}
              >
                <Text>Desaprobar</Text>
              </Button>
            </View>
          </View>
        </Modal>
        // {/* </BlurView> */}
      );
    }
    return (
      <Modal
        animationType={"slide"}
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <BlurView
          intensity={80}
          tint="dark"
          style={[
            StyleSheet.absoluteFill,
            {
              flex: 1,
              flexDirection: "column",
              height,
              width,
              backgroundColor: "#000",
              // zIndex: 9999,
            },
          ]}
        >
          <Icon
            style={{ color: "white" }}
            onPress={() => setModalVisible(!modalVisible)}
            name="close"
          />
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === "ios" ? "padding" : "height"}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Form style={{ width: "100%", height: 100 }}>
                  <Item stackedLabel bordered>
                    <Label>Escribe los motivos de desaprobación</Label>
                    <Input onChangeText={(text) => (reasons = text)} />
                  </Item>
                </Form>
                <Button
                  danger
                  style={{ alignSelf: "center" }}
                  onPress={() => {
                    setModalVisible(!modalVisible);
                    changeRequestStatus(2);
                  }}
                >
                  <Text>Desaprobar</Text>
                </Button>
              </View>
            </View>
          </KeyboardAvoidingView>
        </BlurView>
      </Modal>
    );
  };

  if (Platform.OS === "web") {
    if (logged === loggedValues.COMPRAS) {
      const bp = partners.map((element) => (
        <Picker.Item
          label={element.name}
          value={element.manager}
          key={element.manager}
        />
      ));
      return (
        <Container style={styles.container}>
          {modalVisible && <RenderModal />}
          <SafeAreaView style={styles.content}>
            <ScrollView>
              <Card>
                <View style={{ flexDirection: "row" }}>
                  <CardItem>
                    <View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text>Proyecto: {data.name}</Text>
                        <Text>Q: {data.q}</Text>
                      </View>

                      <Text>Solicitante: {data.requested_by?.full_name}</Text>
                    </View>
                  </CardItem>
                  <CardItem>
                    <View>
                      <Text numberOfLines={3}>
                        Presupuesto actual:{" "}
                        <Text>
                          {numeral(data.capex?.budget).format("000,000.00")}
                        </Text>
                      </Text>
                      <Text numberOfLines={3}>
                        Cantidad solicitada:{" "}
                        <Text style={{ color: "green" }}>
                          {numeral(data.qty_requested).format("000,000.00")}
                        </Text>
                      </Text>
                      <Text numberOfLines={3}>
                        Presupuesto final:{" "}
                        <Text>
                          {numeral(
                            data.capex?.budget - data.qty_requested
                          ).format("000,000.00")}
                        </Text>
                      </Text>
                    </View>
                  </CardItem>
                </View>
                <CardItem>
                  <View>
                    <Text style={{ alignSelf: "flex-end" }}>
                      {new Date(data.created_at).toLocaleDateString()}
                    </Text>
                    <Text numberOfLines={2}>
                      Descripción: {data.description}
                    </Text>
                    <Text numberOfLines={2}>
                      Detalles de compra: {data.details}
                    </Text>
                  </View>
                </CardItem>
              </Card>
              <Card>
                <View style={{ flexDirection: "row" }}>
                  <CardItem>
                    <View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text>Modelo: {data.model}</Text>
                      </View>
                      <Text>Marca: {data.brand}</Text>
                    </View>
                  </CardItem>
                </View>
              </Card>
              <View>
                <Card>
                  <CardItem>
                    <View>
                      <Text>Arrendamiento</Text>
                    </View>
                  </CardItem>
                  <CardItem>
                    <Form style={{ width: "100%" }}>
                      <Item>
                        <Label>Requerido: </Label>
                        <Picker
                          note
                          mode="dropdown"
                          style={{ width: "70%", height: 50 }}
                          selectedValue={dataForm.lease}
                          onValueChange={(item) =>
                            handleChange({ lease: item })
                          }
                        >
                          <Picker.Item
                            label="Selecciona un valor"
                            value={null}
                          />
                          <Picker.Item label="Si" value="Si" />
                          <Picker.Item label="No" value="No" />
                        </Picker>
                      </Item>
                      <Item>
                        <Label>Tipo: </Label>
                        <Picker
                          note
                          mode="dropdown"
                          style={{ width: "70%", height: 50 }}
                          selectedValue={dataForm.type}
                          onValueChange={(item) => handleChange({ type: item })}
                        >
                          <Picker.Item
                            label="Selecciona un valor"
                            value={null}
                          />
                          <Picker.Item label="Financiero" value="Financiero" />
                          <Picker.Item
                            label="Operacional"
                            value="Operacional"
                          />
                        </Picker>
                      </Item>
                      <Item stackedLabel>
                        <Label>Periodo de depreciación (años): </Label>
                        <Input
                          placeholder=""
                          value={dataForm.period_deprecation}
                          keyboardType="number-pad"
                          onChangeText={(text) =>
                            handleChange({ period_deprecation: text })
                          }
                          // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                        />
                      </Item>
                    </Form>
                  </CardItem>
                  <CardItem>
                    <View>
                      <Text>Costo mensual</Text>
                    </View>
                  </CardItem>
                  <CardItem>
                    <Form style={{ width: "100%" }}>
                      <Item stackedLabel>
                        <Label>Capital $: </Label>
                        <Input
                          placeholder=""
                          value={dataForm.monthly_cost}
                          keyboardType="number-pad"
                          onChangeText={(text) =>
                            handleChange({ monthly_cost: text })
                          }
                          // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                        />
                      </Item>
                      <Item stackedLabel>
                        <Label>Interes $: </Label>
                        <Input
                          placeholder=""
                          value={dataForm.interest}
                          keyboardType="number-pad"
                          onChangeText={(text) =>
                            handleChange({ interest: text })
                          }
                          // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                        />
                      </Item>
                    </Form>
                  </CardItem>
                  <CardItem>
                    <Form style={{ width: "100%" }}>
                      <Item stackedLabel>
                        <Label>Condiciones de compra: </Label>
                        <Input
                          placeholder=""
                          value={dataForm.purchase_condition}
                          onChangeText={(text) =>
                            handleChange({ purchase_condition: text })
                          }
                          // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                        />
                      </Item>
                      <Item>
                        <Label>BP a notificar: </Label>
                        <Picker
                          note
                          mode="dropdown"
                          style={{ width: "70%", height: 50 }}
                          selectedValue={dataForm.reviewer}
                          onValueChange={(item) =>
                            handleChange({ reviewer: item })
                          }
                        >
                          <Picker.Item
                            label="Selecciona un valor"
                            value={null}
                          />
                          {/* <Picker.Item label="Financiero" value="Financiero" />
                          <Picker.Item
                            label="Operacional"
                            value="Operacional"
                          /> */}
                          {bp}
                        </Picker>
                      </Item>
                    </Form>
                  </CardItem>
                </Card>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    height: 200,
                  }}
                >
                  <Button
                    full
                    danger
                    onPress={() => setModalVisible(true)}
                    style={{ width: "45%" }}
                  >
                    <Text>Desaprobar</Text>
                    <Icon name="close" />
                  </Button>
                  <Button
                    full
                    info
                    disabled={hasNulls(dataForm)}
                    onPress={() => changeRequestStatus(1)}
                    style={{ width: "45%" }}
                  >
                    <Text>Aprobar</Text>
                    <Icon name="checkmark" />
                  </Button>
                </View>
              </View>
            </ScrollView>
          </SafeAreaView>
        </Container>
      );
    }

    return (
      <Container style={styles.container}>
        {modalVisible && <RenderModal />}
        <SafeAreaView style={styles.content}>
          <ScrollView>
            <Card>
              <View style={{ flexDirection: "row" }}>
                <CardItem>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>Proyecto: {data.name}</Text>
                      <Text>Q: {data.q}</Text>
                    </View>

                    <Text>Solicitante: {data.requested_by?.full_name}</Text>
                  </View>
                </CardItem>
                <CardItem>
                  <View>
                    <Text numberOfLines={3}>
                      Presupuesto actual:{" "}
                      <Text>
                        {numeral(data.capex?.budget).format("000,000.00")}
                      </Text>
                    </Text>
                    <Text numberOfLines={3}>
                      Cantidad solicitada:{" "}
                      <Text style={{ color: "green" }}>
                        {numeral(data.qty_requested).format("000,000.00")}
                      </Text>
                    </Text>
                    <Text numberOfLines={3}>
                      Presupuesto final:{" "}
                      <Text>
                        {numeral(
                          data.capex?.budget - data.qty_requested
                        ).format("000,000.00")}
                      </Text>
                    </Text>
                  </View>
                </CardItem>
                <CardItem>
                  <View>
                    <Text style={{ alignSelf: "flex-end", fontWeight: "bold" }}>
                      {new Date(data.created_at).toLocaleDateString()}
                    </Text>
                    <Text numberOfLines={2}>
                      Descripción: {data.description}
                    </Text>
                    <Text numberOfLines={2}>
                      Detalles de compra: {data.details}
                    </Text>
                  </View>
                </CardItem>
              </View>
            </Card>
            <Card>
              <View style={{ flexDirection: "row" }}>
                <CardItem>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>Modelo: {data.model}</Text>
                    </View>
                    <Text>Marca: {data.brand}</Text>
                  </View>
                </CardItem>
              </View>
            </Card>
            <View>
              <Card>
                <CardItem>
                  <View>
                    <Text>Arrendamiento</Text>
                  </View>
                </CardItem>
                <CardItem>
                  <Form style={{ width: "100%" }}>
                    <Item>
                      <Label>Requerido: </Label>
                      <Picker
                        note
                        mode="dropdown"
                        style={{ width: "70%", height: 50 }}
                        selectedValue={data.lease}
                        enabled={false}
                        onValueChange={(item) => handleChange({ lease: item })}
                      >
                        <Picker.Item label="Selecciona un valor" value={null} />
                        <Picker.Item label="Si" value="Si" />
                        <Picker.Item label="No" value="No" />
                      </Picker>
                    </Item>
                    <Item>
                      <Label>Tipo: </Label>
                      <Picker
                        note
                        mode="dropdown"
                        style={{ width: "70%", height: 50 }}
                        selectedValue={data.type}
                        enabled={false}
                        onValueChange={(item) => handleChange({ type: item })}
                      >
                        <Picker.Item label="Selecciona un valor" value={null} />
                        <Picker.Item label="Financiero" value="Financiero" />
                        <Picker.Item label="Operacional" value="Operacional" />
                      </Picker>
                    </Item>
                    <Item>
                      <Label>Periodo de depreciación (años): </Label>
                      <Input
                        placeholder=""
                        value={data.period_deprecation}
                        keyboardType="number-pad"
                        editable={false}
                        onChangeText={(text) =>
                          handleChange({ period_deprecation: text })
                        }
                        // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                      />
                    </Item>
                  </Form>
                </CardItem>
                <CardItem>
                  <Form style={{ width: "100%" }}>
                    <Item>
                      <Label>Costo mensual $: </Label>
                      <Input
                        placeholder=""
                        defaultValue={`${data.monthly_cost}`}
                        keyboardType="number-pad"
                        editable={false}
                        onChangeText={(text) =>
                          handleChange({ monthly_cost: text })
                        }
                        // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                      />
                    </Item>
                    <Item>
                      <Label>Interes $: </Label>
                      <Input
                        placeholder=""
                        defaultValue={`${data.interest}`}
                        keyboardType="number-pad"
                        editable={false}
                        onChangeText={(text) =>
                          handleChange({ interest: text })
                        }
                        // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                      />
                    </Item>
                  </Form>
                </CardItem>
                <CardItem>
                  <Form style={{ width: "100%" }}>
                    <Item>
                      <Label>Condiciones de compra: </Label>
                      <Input
                        placeholder=""
                        editable={false}
                        defaultValue={data.purchase_condition}
                        onChangeText={(text) =>
                          handleChange({ purchase_condition: text })
                        }
                        // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                      />
                    </Item>
                  </Form>
                </CardItem>
              </Card>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "5%",
                }}
              >
                <Button
                  full
                  danger
                  onPress={() => setModalVisible(true)}
                  style={{ width: "45%" }}
                >
                  <Text>Desaprobar</Text>
                  <Icon name="close" />
                </Button>
                <Button
                  full
                  info
                  onPress={() => changeRequestStatus(1)}
                  style={{ width: "45%" }}
                >
                  <Text>Aprobar</Text>
                  <Icon name="checkmark" />
                </Button>
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
      </Container>
    );
  }

  if (logged === loggedValues.COMPRAS) {
    return (
      <Container style={styles.container}>
        {modalVisible && <RenderModal />}
        <SafeAreaView style={styles.content}>
          <ScrollView>
            <Card>
              <CardItem>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>Proyecto: {data.name}</Text>
                    <Text>
                      {new Date(data.created_at).toLocaleDateString()}
                    </Text>
                  </View>
                  <Text>Q: {data.q}</Text>
                  <Text>Solicitante: {data.requested_by?.full_name}</Text>
                </View>
              </CardItem>
              <CardItem>
                <View>
                  <Text numberOfLines={2}>Descripción: {data.description}</Text>
                  <Text numberOfLines={2}>
                    Detalles de compra: {data.details}
                  </Text>
                </View>
              </CardItem>
              <CardItem>
                <View>
                  <Text numberOfLines={3}>
                    Presupuesto actual:{" "}
                    <Text>
                      {numeral(data.capex?.budget).format("000,000.00")}
                    </Text>
                  </Text>
                  <Text numberOfLines={3}>
                    Cantidad solicitada:{" "}
                    <Text>
                      {numeral(data.qty_requested).format("000,000.00")}
                    </Text>
                  </Text>
                  <Text numberOfLines={3}>
                    Presupuesto final:{" "}
                    <Text>
                      {numeral(data.capex?.budget - data.qty_requested).format(
                        "000,000.00"
                      )}
                    </Text>
                  </Text>
                </View>
              </CardItem>
            </Card>
            <Card>
              <View style={{ flexDirection: "row" }}>
                <CardItem>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>Modelo: {data.model}</Text>
                    </View>
                    <Text>Marca: {data.brand}</Text>
                  </View>
                </CardItem>
              </View>
            </Card>
            <View>
              <Card>
                <CardItem>
                  <View>
                    <Text>Arrendamiento</Text>
                  </View>
                </CardItem>
                <CardItem>
                  <Form style={{ width: "100%" }}>
                    <Item>
                      <Label>Requerido: </Label>
                      <Picker
                        note
                        mode="dropdown"
                        style={{ width: "70%", height: 50 }}
                        selectedValue={dataForm.lease}
                        onValueChange={(item) => handleChange({ lease: item })}
                      >
                        <Picker.Item label="Selecciona un valor" value={null} />
                        <Picker.Item label="Si" value="Si" />
                        <Picker.Item label="No" value="No" />
                      </Picker>
                    </Item>
                    <Item>
                      <Label>Tipo: </Label>
                      <Picker
                        note
                        mode="dropdown"
                        style={{ width: "70%", height: 50 }}
                        selectedValue={dataForm.type}
                        onValueChange={(item) => handleChange({ type: item })}
                      >
                        <Picker.Item label="Selecciona un valor" value={null} />
                        <Picker.Item label="Financiero" value="Financiero" />
                        <Picker.Item label="Operacional" value="Operacional" />
                      </Picker>
                    </Item>
                    <Item>
                      <Label>Periodo de depreciación (años): </Label>
                      <Input
                        placeholder=""
                        value={dataForm.period_deprecation}
                        keyboardType="number-pad"
                        onChangeText={(text) =>
                          handleChange({ period_deprecation: text })
                        }
                        // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                      />
                    </Item>
                  </Form>
                </CardItem>
                <CardItem>
                  <View>
                    <Text>Costo mensual</Text>
                  </View>
                </CardItem>
                <CardItem>
                  <Form style={{ width: "100%" }}>
                    <Item>
                      <Label>Capital $: </Label>
                      <Input
                        placeholder=""
                        value={dataForm.monthly_cost}
                        keyboardType="number-pad"
                        onChangeText={(text) =>
                          handleChange({ monthly_cost: text })
                        }
                        // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                      />
                    </Item>
                    <Item>
                      <Label>Interes $: </Label>
                      <Input
                        placeholder=""
                        value={dataForm.interest}
                        keyboardType="number-pad"
                        onChangeText={(text) =>
                          handleChange({ interest: text })
                        }
                        // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                      />
                    </Item>
                  </Form>
                </CardItem>
                <CardItem>
                  <Form style={{ width: "100%" }}>
                    <Item>
                      <Label>Condiciones de compra: </Label>
                      <Input
                        placeholder=""
                        value={dataForm.purchase_condition}
                        onChangeText={(text) =>
                          handleChange({ purchase_condition: text })
                        }
                        // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                      />
                    </Item>
                    <Item>
                      <Label>BP a notificar: </Label>
                      <Picker
                        note
                        mode="dropdown"
                        style={{ width: "70%", height: 50 }}
                        selectedValue={dataForm.reviewer}
                        onValueChange={(item) =>
                          handleChange({ reviewer: item })
                        }
                      >
                        <Picker.Item label="Selecciona un valor" value={null} />
                        {/* <Picker.Item label="Financiero" value="Financiero" />
                        <Picker.Item label="Operacional" value="Operacional" /> */}
                        {bp}
                      </Picker>
                    </Item>
                  </Form>
                </CardItem>
              </Card>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "10%",
              }}
            >
              <Button
                full
                danger
                onPress={() => setModalVisible(true)}
                style={{ width: "45%" }}
              >
                <Text>Desaprobar</Text>
                <Icon name="close" />
              </Button>
              <Button
                full
                info
                onPress={() => changeRequestStatus(1)}
                style={{ width: "45%" }}
              >
                <Text>Aprobar</Text>
                <Icon name="checkmark" />
              </Button>
            </View>
          </ScrollView>
        </SafeAreaView>
      </Container>
    );
  }

  return (
    <Container style={styles.container}>
      {modalVisible && <RenderModal />}
      <SafeAreaView style={styles.content}>
        <ScrollView>
          <Card>
            <CardItem>
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text>Proyecto: {data.name}</Text>
                  <Text>{new Date(data.created_at).toLocaleDateString()}</Text>
                </View>
                <Text>Q: {data.q}</Text>
                <Text>Solicitante: {data.requested_by?.full_name}</Text>
              </View>
            </CardItem>
            <CardItem>
              <View>
                <Text numberOfLines={2}>Descripción: {data.description}</Text>
                <Text numberOfLines={2}>
                  Detalles de compra: {data.details}
                </Text>
              </View>
            </CardItem>
            <CardItem>
              <View>
                <Text numberOfLines={3}>
                  Presupuesto actual:{" "}
                  <Text>
                    {numeral(data.capex?.budget).format("000,000.00")}
                  </Text>
                </Text>
                <Text numberOfLines={3}>
                  Cantidad solicitada:{" "}
                  <Text>
                    {numeral(data.qty_requested).format("000,000.00")}
                  </Text>
                </Text>
                <Text numberOfLines={3}>
                  Presupuesto final:{" "}
                  <Text>
                    {numeral(data.capex?.budget - data.qty_requested).format(
                      "000,000.00"
                    )}
                  </Text>
                </Text>
              </View>
            </CardItem>
          </Card>
          <Card>
            <View style={{ flexDirection: "row" }}>
              <CardItem>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>Modelo: {data.model}</Text>
                  </View>
                  <Text>Marca: {data.brand}</Text>
                </View>
              </CardItem>
            </View>
          </Card>
          <View>
            <Card>
              <CardItem>
                <View>
                  <Text>Arrendamiento</Text>
                </View>
              </CardItem>
              <CardItem>
                <Form style={{ width: "100%" }}>
                  <Item>
                    <Label>Requerido: </Label>
                    <Picker
                      note
                      mode="dropdown"
                      style={{ width: "70%", height: 50 }}
                      selectedValue={data.lease}
                      enabled={false}
                      onValueChange={(item) => handleChange({ lease: item })}
                    >
                      <Picker.Item label="Selecciona un valor" value={null} />
                      <Picker.Item label="Si" value="Si" />
                      <Picker.Item label="No" value="No" />
                    </Picker>
                  </Item>
                  <Item>
                    <Label>Tipo: </Label>
                    <Picker
                      note
                      mode="dropdown"
                      style={{ width: "70%", height: 50 }}
                      selectedValue={data.type}
                      enabled={false}
                      onValueChange={(item) => handleChange({ type: item })}
                    >
                      <Picker.Item label="Selecciona un valor" value={null} />
                      <Picker.Item label="Financiero" value="Financiero" />
                      <Picker.Item label="Operacional" value="Operacional" />
                    </Picker>
                  </Item>
                  <Item>
                    <Label>Periodo de depreciación (años): </Label>
                    <Input
                      placeholder=""
                      value={data.period_deprecation}
                      keyboardType="number-pad"
                      editable={false}
                      onChangeText={(text) =>
                        handleChange({ period_deprecation: text })
                      }
                      // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                    />
                  </Item>
                </Form>
              </CardItem>
              <CardItem>
                <Form style={{ width: "100%" }}>
                  <Item>
                    <Label>Costo mensual $: </Label>
                    <Input
                      placeholder=""
                      defaultValue={`${data.monthly_cost}`}
                      keyboardType="number-pad"
                      editable={false}
                      onChangeText={(text) =>
                        handleChange({ monthly_cost: text })
                      }
                      // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                    />
                  </Item>
                  <Item>
                    <Label>Interes $: </Label>
                    <Input
                      placeholder=""
                      defaultValue={`${data.interest}`}
                      keyboardType="number-pad"
                      editable={false}
                      onChangeText={(text) => handleChange({ interest: text })}
                      // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                    />
                  </Item>
                </Form>
              </CardItem>
              <CardItem>
                <Form style={{ width: "100%" }}>
                  <Item>
                    <Label>Condiciones de compra: </Label>
                    <Input
                      placeholder=""
                      editable={false}
                      defaultValue={data.purchase_condition}
                      onChangeText={(text) =>
                        handleChange({ purchase_condition: text })
                      }
                      // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                    />
                  </Item>
                </Form>
              </CardItem>
            </Card>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10%",
            }}
          >
            <Button
              full
              danger
              onPress={() => setModalVisible(true)}
              style={{ width: "45%" }}
            >
              <Text>Desaprobar</Text>
              <Icon name="close" />
            </Button>
            <Button
              full
              info
              onPress={() => changeRequestStatus(1)}
              style={{ width: "45%" }}
            >
              <Text>Aprobar</Text>
              <Icon name="checkmark" />
            </Button>
          </View>
        </ScrollView>
      </SafeAreaView>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 10,
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
    }),
  },
});
