import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';

import TabBarIcon from '../components/TabBarIcon';
import SettingsScreen from '../screens/SettingsScreen';

const BottomTab = createBottomTabNavigator();
const INITIAL_ROUTE_NAME = 'Sheet';

export default function BottomTabSheetsNavigator({ navigation, route }) {
  const drawer = React.useRef(null);
  // Set the header title on the parent stack navigator depending on the
  // currently active tab. Learn more in the documentation:
  // https://reactnavigation.org/docs/en/screen-options-resolution.html
  navigation.setOptions({
    headerTitle: getHeaderTitle(route),
    headerShown: showHeader(route),
    // headerLeft: () => (
    //   leftIcon(drawer, 'menu')
    // ),
  });
  // console.log(navigation);


  return (
    <BottomTab.Navigator initialRouteName={INITIAL_ROUTE_NAME}>
        {/* <BottomTab.Screen
          name="Sheet"
          component={SheetStack}
          options={{
            title: 'Sincronización',
            tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-code-working" />,
          }}
        /> */}
      <BottomTab.Screen
        name="SettingsScreen"
        component={SettingsScreen}
        options={{
          title: 'Configuración',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-settings" />,
        }}
      />
    </BottomTab.Navigator>
  );
}

function getHeaderTitle(route) {
  const routeName = route.state?.routes[route.state.index]?.name ?? INITIAL_ROUTE_NAME;
  const parent = route.state ? route.state.routes[route.state.index].state : null;
  const childName = parent ? parent.routes[parent.index].name : null
  switch (routeName) {
    case 'Sheet':
      switch (childName) {
        case 'SheetsScreen':
          return 'Sincronización';
        default:
          return 'Escritorio';
      }
    case 'SettingsScreen':
      return 'Configuración';
  }
}

function showHeader(route) {
  const routeName = route.state?.routes[route.state.index]?.name ?? INITIAL_ROUTE_NAME;
  switch (routeName) {
    case 'SettingsScreen':
      return true;
    default:
      return false;
  }
}
