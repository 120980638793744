import Axios from 'axios';
import { Button, Card, CardItem, Container, Form, Icon, Input, Item, Label, Picker, Text, Textarea, View } from 'native-base';
import * as React from 'react';
import { Alert, Keyboard, KeyboardAvoidingView, Platform, StyleSheet, TouchableWithoutFeedback, ViewBase } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import PreLoader from '../../components/PreLoader';
import { hasNulls } from '../../utils/checkNulls';
import config from '../../utils/config';
import HandleHTTPError from '../../utils/handleErrors';

var numeral = require('numeral');

export default function CreateSubRequestScreen(props) {
    const { navigation, route } = props;
    const capex = route.params?.capex;
    const [working, setWorking] = React.useState(false);
    const [dataForm, setDataForm] = React.useState({
        "capex": capex.id,
        "budget_requested": null,
        "description": null,
        "q": 1,
        // "objective": null
    });
    const [availableBudget, setAvailableBudget] = React.useState(capex.budget - (capex.current_budget > 0 ? capex.current_budget : capex.current_budget * -1))

    const handleChange = (val) => {
        const data = { ...dataForm, ...val }
        setDataForm(data);
    }

    const calculateAvailable = (val) => {
        const qty = parseInt(val);
        if (isNaN(qty)) {
            const available = 0;
            setAvailableBudget(available)
        } else {
            const available = capex.budget - qty;
            setAvailableBudget(available)
        }

    }

    const makeRequest = () => {
        const uri = `${config.api.host}requisitions/sub`;
        setWorking(true);
        if(dataForm.budget_requested < 0){
            if(Platform.OS === 'web'){
              alert('La cantidad solicitada no debe ser menor a 0');
            } else {
              Alert.alert('La cantidad solicitada no debe ser menor a 0');
            }
            setWorking(false)
            return;
          }
        Axios.post(uri, dataForm, {
            headers: config.api.headers
        })
            .then((response) => {
                setWorking(false);
                if (Platform.OS === 'web') {
                    alert('Solicitud enviada correctamente', ' La solicitud entró en proceso de aprobación')
                } else {
                    Alert.alert('Solicitud enviada correctamente', ' La solicitud entró en proceso de aprobación')
                }
                navigation.navigate('HomeScreen')
            })
            .catch((error) => {
                HandleHTTPError(error, navigation)
                setWorking(false);
            })
    }

    if (working) {
        return <PreLoader />;
    }

    if (Platform.OS === 'web') {
        return (
            <Container style={styles.container}>
                <SafeAreaView style={styles.content}>
                    <View style={{ justifyContent: "space-around" }}>
                        <Form style={{ width: '100%' }}>
                            <View>
                                <CardItem>
                                    <Text style={{ fontWeight: 'bold' }}>{`CAPEX: ${capex.name}`}</Text>
                                </CardItem>
                                <CardItem>
                                    <Text>{`Descripción: ${capex.description}`}</Text>
                                </CardItem>
                                <CardItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Text>{`Presupuesto Original: `}<Text style={{ color: '#94bf8d' }}>{numeral(capex.budget).format('000,000.00')}</Text></Text>
                                </CardItem>
                                <CardItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Text>{`Presupuesto Usado: `}<Text style={{ color: '#ed6105' }}>{numeral(availableBudget).format('000,000.00')}</Text></Text>
                                </CardItem>
                                <CardItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Text>{`Presupuesto Disponible: `}<Text style={{ color: '#94bf8d' }}>{numeral(capex.current_budget).format('000,000.00')}</Text></Text>
                                </CardItem>
                            </View>
                            <Item>
                                <Label>Nombre del proyecto</Label>
                                <Input editable={false} value={`${capex.name}_Sub.${`${capex.code_sub}`.padStart(2, '0')}`} numberOfLines={4} />
                            </Item>
                            <Item stackedLabel>
                                <Label>Descripción: </Label>
                                <Input placeholder='' numberOfLines={4} onChangeText={(text) => handleChange({ description: text })} />
                            </Item>
                            <Item stackedLabel>
                                <Label>Cantidad a transladar: </Label>
                                <Input placeholder=''
                                    keyboardType="number-pad"
                                    onChangeText={(text) => handleChange({ budget_requested: text })}
                                    onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                                />
                            </Item>
                            <Item>
                                <Label>Cuatrimestre</Label>
                                <Picker
                                    note
                                    mode="dropdown"
                                    style={{ width: '70%', height: 100 }}
                                    selectedValue={dataForm.q}
                                    onValueChange={(item) => handleChange({ q: item })}
                                >
                                    <Picker.Item label="1" value={1} />
                                    <Picker.Item label="2" value={2} />
                                    <Picker.Item label="3" value={3} />
                                </Picker>
                            </Item>
                        </Form>
                    </View>
                    <Button large style={{ alignSelf: 'center', marginTop: '10%' }} info disabled={hasNulls(dataForm)} onPress={() => makeRequest()}>
                        <Icon name='paper-plane-outline' />
                        <Text>Enviar</Text>
                    </Button>
                </SafeAreaView>
            </Container>
        )
    }

    return (
        <Container style={styles.container}>
            <SafeAreaView style={styles.content}>
                <KeyboardAvoidingView style={{ flex: 1 }} >
                    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                        <View style={{ justifyContent: "space-around" }}>
                            <Form style={{ width: '100%' }}>
                                <View>
                                    <CardItem>
                                        <Text style={{ fontWeight: 'bold' }}>{`CAPEX: ${capex.name}`}</Text>
                                    </CardItem>
                                    <CardItem>
                                        <Text>{`Descripción: ${capex.description}`}</Text>
                                    </CardItem>
                                    <CardItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Text>{`Presupuesto Original: `}<Text style={{ color: '#94bf8d' }}>{numeral(capex.budget).format('000,000.00')}</Text></Text>
                                    </CardItem>
                                    <CardItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Text>{`Presupuesto Usado: `}<Text style={{ color: '#ed6105' }}>{numeral(availableBudget).format('000,000.00')}</Text></Text>
                                    </CardItem>
                                    <CardItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Text>{`Presupuesto Disponible: `}<Text style={{ color: '#94bf8d' }}>{numeral(capex.current_budget).format('000,000.00')}</Text></Text>
                                    </CardItem>
                                    {/* <CardItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Text>{`Descripción:`}<Text> {dataForm.description}</Text></Text>
                                    </CardItem> */}
                                </View>
                                <Item>
                                    <Label>Nombre del proyecto</Label>
                                    <Input editable={false} value={`${capex.name}_Sub.${`${capex.code_sub}`.padStart(2, '0')}`} numberOfLines={4} />
                                </Item>
                                <Item >
                                    <Label>Descripción</Label>
                                    <Input placeholder='' numberOfLines={4} onChangeText={(text) => handleChange({ description: text })} />
                                </Item>
                                <Item>
                                    <Label>Cantidad a transladar</Label>
                                    <Input placeholder=''
                                        keyboardType="number-pad"
                                        onChangeText={(text) => handleChange({ budget_requested: text })}
                                        onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                                    />
                                </Item>
                                <Item>
                                    <Label>Cuatrimestre</Label>
                                    <Picker
                                        note
                                        mode="dropdown"
                                        style={{ width: '70%', height: 50 }}
                                        selectedValue={dataForm.q}
                                        onValueChange={(item) => handleChange({ q: item })}
                                    >
                                        <Picker.Item label="1" value={1} />
                                        <Picker.Item label="2" value={2} />
                                        <Picker.Item label="3" value={3} />
                                        <Picker.Item label="4" value={4} />
                                    </Picker>
                                </Item>
                            </Form>
                        </View>
                    </TouchableWithoutFeedback>
                </KeyboardAvoidingView>
                <Button block info disabled={hasNulls(dataForm)} onPress={() => makeRequest()}><Text>Enviar</Text></Button>
            </SafeAreaView>
        </Container>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: '#fff',

    },
    content: {
        flex: 1, padding: 10,
        ...Platform.OS === 'web' && {
            // maxWidth: '70%',
            width: '50%',
            alignSelf: 'center'
        }
    },

});