import * as React from "react";
import { CommonActions } from "@react-navigation/routers";
import { Alert, Platform } from "react-native";

const HandleHTTPError = (error, navigation = null, errorMsg = null) => {
  if (Platform.OS === "web") {
    if (!error.response) {
      alert("Error", "Ha ocurrido un error" + JSON.stringify(error));
    }
    try {
      if (error.response.status === 401) {
        if (__DEV__) {
          console.log("401", error.response);
        }
        if (navigation) {
          const navigateAction = CommonActions.navigate({
            name: "LogoutScreen",
          });
          navigation.dispatch(navigateAction);
        } else {
          if (!__DEV__) {
            alert("Error" + `${error.response.data.detail}`);
          }
        }
      } else {
        if (errorMsg) {
          alert("Error" + errorMsg);
        } else {
          if (__DEV__) {
            if (Array.isArray(error.response.data)) {
              console.log(error.response.data[0]);
            } else {
              console.log(error.response.data);
            }
          } else {
            if (Array.isArray(error.response.data)) {
              alert("Error " + `${JSON.stringify(error.response.data[0])}`);
            } else {
              alert("Error " + `${JSON.stringify(error.response.data)}`);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      alert("Error" + `${JSON.stringify(error)}`);
    }
  } else {
    if (!error.response) {
      Alert.alert("Error", "Ha ocurrido un error");
    }
    try {
      if (error.response.status === 401) {
        if (__DEV__) {
          console.log("401", error.response);
        }
        if (navigation) {
          const navigateAction = CommonActions.navigate({
            name: "LogoutScreen",
          });
          navigation.dispatch(navigateAction);
        } else {
          if (!__DEV__) {
            Alert.alert("Error", `${error.response.data.detail}`);
          }
        }
      } else {
        if (errorMsg) {
          Alert.alert("Error", errorMsg);
        } else {
          // if (__DEV__) {
          //     if (Array.isArray(error.response.data)) {
          //         console.log(error.response.data[0]);
          //     } else {
          //         console.log(error.response.data);
          //     }

          // } else {
          if (Array.isArray(error.response.data)) {
            Alert.alert("Error", `${JSON.stringify(error.response.data[0])}`);
          } else {
            Alert.alert("Error", `${JSON.stringify(error.response.data)}`);
          }

          // }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }
};

export default HandleHTTPError;
