import Axios from "axios";
import { BlurView } from "expo-blur";
import {
  Button,
  Card,
  CardItem,
  Container,
  Form,
  Icon,
  Input,
  Item,
  Label,
  Text,
  View,
} from "native-base";
import * as React from "react";
import {
  Alert,
  Dimensions,
  Platform,
  StyleSheet,
  ViewBase,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import PreLoader from "../../components/PreLoader";
import config from "../../utils/config";
import HandleHTTPError from "../../utils/handleErrors";

var numeral = require("numeral");
const { width, height } = Dimensions.get("window");

export default function AproveCambioScreen(props) {
  const { navigation, route } = props;
  const [working, setWorking] = React.useState(true);
  const cambio = route.params?.item;
  let reasons = "";
  const [data, setData] = React.useState({});
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    const uri = `${config.api.host}requisitions/change/${cambio}`;
    setWorking(true);
    Axios.get(uri, {
      headers: config.api.headers,
    })
      .then(({ data }) => {
        setData(data);
        setWorking(false);
      })
      .catch((error) => {
        if (Platform.OS === "web") {
          alert(`Ha ocurrido un error ${JSON.stringify(error.response)}`);
        } else {
          HandleHTTPError(error, navigation);
        }
        setWorking(false);
      });
  }, []);

  const changeRequestStatus = (status) => {
    setWorking(true);
    const msg =
      status === 1
        ? "La solicitud fue aprobada"
        : "La solicitud fue desaprobada";
    const uri = `${config.api.host}requisitions/change/${cambio}`;

    const body = { status };
    if (status === 2) {
      body.observations = reasons;
    }
    Axios.patch(uri, body, {
      headers: config.api.headers,
    })
      .then(({ data }) => {
        setWorking(false);
        if (Platform.OS === "web") {
          alert("Se cambió el estado de la solicitud", msg);
        } else {
          Alert.alert("Se cambió el estado de la solicitud", msg);
        }
        navigation.navigate("HomeScreen");
      })
      .catch((error) => {
        if (Platform.OS === "web") {
          alert(`Ha ocurrido un error ${JSON.stringify(error.response)}`);
        } else {
          HandleHTTPError(error, navigation);
        }
        setWorking(false);
      });
  };

  if (working) {
    return <PreLoader />;
  }

  if (!data) {
    return (
      <Container>
        <SafeAreaView style={{ flex: 1, padding: 15 }}>
          <Card>
            <CardItem>
              <View>
                <Text numberOfLines={2}>
                  Ha ocurrido un error al obtener la solicitud
                </Text>
              </View>
            </CardItem>
          </Card>
        </SafeAreaView>
      </Container>
    );
  }

  const RenderModal = () => {
    if (Platform.OS === "web") {
      return (
        <BlurView
          intensity={80}
          tint="dark"
          style={[
            StyleSheet.absoluteFill,
            {
              flex: 1,
              // flexDirection: 'column',
              height,
              width,
              backgroundColor: "#000",
              zIndex: 9999,
            },
          ]}
        >
          {/* <Modal
                        animationType={'slide'}
                        transparent={true}
                        visible={modalVisible}
                        onRequestClose={() => setModalVisible(false)}> */}

          <Icon
            style={{ color: "white" }}
            onPress={() => setModalVisible(!modalVisible)}
            name="close"
          />
          <View
            style={{
              flex: 1,
              alignSelf: "center",
              paddingLeft: "40%",
              paddingRight: "40%",
              // justifyContent:'center'
            }}
          >
            <View
              style={{
                backgroundColor: "white",
                borderRadius: 20,
                padding: 35,
                alignItems: "center",
                alignSelf: "center",
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 4,
                elevation: 5,
              }}
            >
              <Form style={{ width: "100%", height: 100 }}>
                <Item stackedLabel bordered>
                  <Label>Escribe los motivos de desaprobación</Label>
                  <Input onChangeText={(text) => (reasons = text)} />
                </Item>
              </Form>
              <Button
                danger
                style={{ alignSelf: "center" }}
                onPress={() => {
                  setModalVisible(!modalVisible);
                  changeRequestStatus(2);
                }}
              >
                <Text>Desaprobar</Text>
              </Button>
            </View>
          </View>
          {/* </Modal> */}
        </BlurView>
      );
    }
    return (
      <Modal
        animationType={"slide"}
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <BlurView
          intensity={80}
          tint="dark"
          style={[
            StyleSheet.absoluteFill,
            {
              flex: 1,
              flexDirection: "column",
              height,
              width,
              backgroundColor: "#000",
              // zIndex: 9999,
            },
          ]}
        >
          <Icon
            style={{ color: "white" }}
            onPress={() => setModalVisible(!modalVisible)}
            name="close"
          />
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === "ios" ? "padding" : "height"}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Form style={{ width: "100%", height: 100 }}>
                  <Item stackedLabel bordered>
                    <Label>Escribe los motivos de desaprobación</Label>
                    <Input onChangeText={(text) => (reasons = text)} />
                  </Item>
                </Form>
                <Button
                  danger
                  style={{ alignSelf: "center" }}
                  onPress={() => {
                    setModalVisible(!modalVisible);
                    changeRequestStatus(2);
                  }}
                >
                  <Text>Desaprobar</Text>
                </Button>
              </View>
            </View>
          </KeyboardAvoidingView>
        </BlurView>
      </Modal>
    );
  };

  if (Platform.OS === "web") {
    return (
      <Container style={styles.container}>
        {modalVisible && <RenderModal />}
        <SafeAreaView style={styles.content}>
          <Card>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <CardItem>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>Proyecto: {data.name}</Text>
                    <Text>Q: {data.q}</Text>
                  </View>

                  <Text>Solicitante: {data.requested_by?.full_name}</Text>
                </View>
              </CardItem>
              <CardItem>
                <View>
                  <Text style={{ alignSelf: "flex-end", fontWeight: "bold" }}>
                    {new Date(data.created_at).toLocaleDateString()}
                  </Text>
                  <Text numberOfLines={2} ellipsizeMode="tail">
                    Descripción de cambio: {data.description}
                  </Text>
                  <Text numberOfLines={2}>
                    Objetivo de cambio: {data.objective}
                  </Text>
                </View>
              </CardItem>
            </View>
          </Card>
          <View style={{ flexDirection: "row" }}>
            <Card style={{ width: "50%" }}>
              <CardItem>
                <View>
                  <Text>Proyecto emisor {data.capex?.name}</Text>
                </View>
              </CardItem>
              <CardItem>
                <Text numberOfLines={3}>
                  Descripción: {data.capex?.description}
                </Text>
              </CardItem>
              <CardItem>
                <View>
                  <Text numberOfLines={3}>
                    Presupuesto inicial:{" "}
                    <Text>
                      {numeral(data.capex?.budget).format("000,000.00")}
                    </Text>
                  </Text>
                  <Text numberOfLines={3}>
                    Cantidad a transladar:{" "}
                    <Text>
                      {numeral(data.budget_requested).format("000,000.00")}
                    </Text>
                  </Text>
                  <Text numberOfLines={3}>
                    Presupuesto final:{" "}
                    <Text>
                      {numeral(
                        data.capex?.budget - data.budget_requested
                      ).format("000,000.00")}
                    </Text>
                  </Text>
                </View>
              </CardItem>
            </Card>
            <Card style={{ width: "50%" }}>
              <CardItem>
                <View>
                  <Text>Proyecto receptor : {data.capex_receiver?.name}</Text>
                </View>
              </CardItem>
              <CardItem>
                <Text numberOfLines={3}>
                  Descripción: {data.capex_receiver?.description}
                </Text>
              </CardItem>
              <CardItem>
                <View>
                  <Text numberOfLines={3}>
                    Presupuesto disponible actual:{" "}
                    <Text>
                      {numeral(data.capex_receiver?.current_budget).format(
                        "000,000.00"
                      )}
                    </Text>
                  </Text>
                  <Text numberOfLines={3}>
                    Presupuesto disponible final:{" "}
                    <Text style={{ color: "#94bf8d" }}>
                      {numeral(
                        parseFloat(data.capex_receiver?.current_budget) +
                          parseFloat(data.budget_requested)
                      ).format("000,000.00")}
                    </Text>
                  </Text>
                </View>
              </CardItem>
            </Card>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10%",
            }}
          >
            <Button
              full
              danger
              onPress={() => setModalVisible(true)}
              style={{ width: "45%" }}
            >
              <Text>Desaprobar</Text>
              <Icon name="close" />
            </Button>
            <Button
              full
              info
              onPress={() => changeRequestStatus(1)}
              style={{ width: "45%" }}
            >
              <Text>Aprobar</Text>
              <Icon name="checkmark" />
            </Button>
          </View>
        </SafeAreaView>
      </Container>
    );
  }

  return (
    <Container style={styles.container}>
      {modalVisible && <RenderModal />}
      <SafeAreaView style={styles.content}>
        <Card>
          <CardItem>
            <View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Proyecto: {data.name}</Text>
                <Text>{new Date(data.created_at).toLocaleDateString()}</Text>
              </View>
              <Text>Q: {data.q}</Text>
              <Text>Solicitante: {data.requested_by?.full_name}</Text>
            </View>
          </CardItem>
          <CardItem>
            <View>
              <Text numberOfLines={2}>
                Descripción de cambio: {data.description}
              </Text>
              <Text numberOfLines={2}>
                Objetivo de cambio: {data.objective}
              </Text>
            </View>
          </CardItem>
        </Card>
        <View style={{ flexDirection: "row" }}>
          <Card style={{ width: "50%" }}>
            <CardItem>
              <View>
                <Text>Proyecto emisor {data.capex?.name}</Text>
              </View>
            </CardItem>
            <CardItem>
              <View>
                <Text numberOfLines={3}>
                  Descripción: {data.capex?.description}
                </Text>
                <Text numberOfLines={3}>
                  Presupuesto inicial:{" "}
                  <Text>
                    {numeral(data.capex?.budget).format("000,000.00")}
                  </Text>
                </Text>
                <Text numberOfLines={3}>
                  Cantidad a transladar:{" "}
                  <Text>
                    {numeral(data.budget_requested).format("000,000.00")}
                  </Text>
                </Text>
                <Text numberOfLines={3}>
                  Presupuesto final:{" "}
                  <Text>
                    {numeral(data.capex?.budget - data.budget_requested).format(
                      "000,000.00"
                    )}
                  </Text>
                </Text>
              </View>
            </CardItem>
          </Card>
          <Card style={{ width: "50%" }}>
            <CardItem>
              <View>
                <Text>Proyecto receptor : {data.capex_receiver?.name}</Text>
              </View>
            </CardItem>
            <CardItem>
              <View>
                <Text numberOfLines={3}>
                  Descripción: {data.capex_receiver?.description}
                </Text>
                <Text numberOfLines={3}>
                  Presupuesto disponible actual:{" "}
                  <Text>
                    {numeral(data.capex_receiver?.current_budget).format(
                      "000,000.00"
                    )}
                  </Text>
                </Text>
                <Text numberOfLines={3}>
                  Presupuesto disponible final:{" "}
                  <Text style={{ color: "#94bf8d" }}>
                    {numeral(
                      parseFloat(data.capex_receiver?.current_budget) +
                        parseFloat(data.budget_requested)
                    ).format("000,000.00")}
                  </Text>
                </Text>
              </View>
            </CardItem>
          </Card>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10%",
          }}
        >
          <Button
            full
            danger
            onPress={() => setModalVisible(true)}
            style={{ width: "45%" }}
          >
            <Text>Desaprobar</Text>
            <Icon name="close" />
          </Button>
          <Button
            full
            info
            onPress={() => changeRequestStatus(1)}
            style={{ width: "45%" }}
          >
            <Text>Aprobar</Text>
            <Icon name="checkmark" />
          </Button>
        </View>
      </SafeAreaView>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 10,
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
    }),
  },
});
