import Axios from "axios";
import config from "./config";
import { Platform } from "react-native";
import mime from "mime";

const uploadFileAsync = async (file, url, reference) => {
    // const headers = config.api.headers;
    const headers = { ...config.api.headers, ...{ 'Content-Type': 'multipart/form-data' } };
    const endpoint = url; // Define Endpoint Here
    const payloadKey = 'attached_file'; // Define PayloadKey here Ex. 'file'
    const method = 'POST';
    let localUri = file.uri;
    let filename = localUri.split('/').pop();
    let name = file.name.split('.').pop();

    // Infer the type of the image
    let match = /\.(\w+)$/.exec(filename);
    // let type = match ? `image/${match[1]}` : `image`;
    let type = mime.getType(file.uri);

    // Upload the image using the fetch and FormData APIs
    let formData = await new FormData();
    if (Platform.OS === 'web') {
        const res = await fetch(file.uri);
        const blob = await res.blob();
        const Nfile = new File([blob], `${file.file.name}`, {
            type: file.file.type
        });
        formData.append(payloadKey, Nfile);
        formData.append('reference', reference);
        formData.append('name', `${file.file.name}`);
    } else {
        formData.append(payloadKey, { uri: Platform.OS === "android" ? localUri : localUri.replace("file://", ""), name: filename, type });
        formData.append('reference', reference);
        formData.append('name', `${filename}`);
    }


    const options = {
        headers,
    };
    return Axios.post(endpoint, formData, options);
};


export default uploadFileAsync;