import Request from '../constants/Request';

export default {
  api: {
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    host: 'https://capex.desarrollapps.com/api/v1/', //prod
    hostAnnalise: 'https://mercadomovil.com.mx/api/v1/',
    // host: 'http://192.168.68.101:9000/api/v1/',
    geoHost: 'http://tingsystems.com/api/v1/geo/',
    client_Id: 'CKLZaUXlx9ay0437WgsElHxKLMx0ZW4MFFrzNwG3',
    priceList: false,
    route: null,
    logged: false,
  },
};
