import React, { Component, useContext } from "react";
import {
  Text,
  Icon,
  ListItem,
  Content,
  Left,
  Body,
  Right,
  Badge,
  Button,
  Header,
  Container,
  Title,
  View,
} from "native-base";
import styles from "./style";
import { LoggContext, UserContext } from "../../stores/Store";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import { StyleSheet } from "react-native";
import { loggedValues } from "../../utils/enums";
import Colors from "../../constants/Colors";

const logo = require("../../assets/images/icon2.png");

const renderReports = (navigation) => (
  <LoggContext.Consumer>
    {([logged]) => {
      if (logged === loggedValues.COMPRAS) {
        return (
          <View>
            <ListItem
              button
              onPress={() => {
                navigation.navigate("BudgetLimitScreen");
              }}
              icon
              style={styles.links}
            >
              <Icon active name="md-cash" style={{ color: "#ffa726" }} />
              <Body style={{ borderColor: "transparent" }}>
                <Text style={{ color: "#555", marginLeft: 15 }}>
                  Limites de presupuesto
                </Text>
              </Body>
            </ListItem>
            <ListItem
              button
              onPress={() => {
                navigation.navigate("ReportsScreen");
              }}
              icon
              style={styles.links}
            >
              <Icon active name="md-bar-chart" style={{ color: "#ffa726" }} />
              <Body style={{ borderColor: "transparent" }}>
                <Text style={{ color: "#555", marginLeft: 15 }}>Reportes</Text>
              </Body>
            </ListItem>

            <ListItem
              button
              onPress={() => {
                navigation.navigate("RequestListAutoScreen");
              }}
              icon
              style={styles.links}
            >
              <Icon active name="md-car" style={{ color: "#ffa726" }} />
              <Body style={{ borderColor: "transparent" }}>
                <Text style={{ color: "#555", marginLeft: 15 }}>
                  Estatus de solicitudes AUTO
                </Text>
              </Body>
            </ListItem>
            <ListItem
              button
              onPress={() => {
                navigation.navigate("RequestListScreen");
              }}
              icon
              style={styles.links}
            >
              <Icon active name="md-stats-chart" style={{ color: "#ffa726" }} />
              <Body style={{ borderColor: "transparent" }}>
                <Text style={{ color: "#555", marginLeft: 15 }}>
                  Estatus de solicitudes
                </Text>
              </Body>
            </ListItem>
          </View>
        );
      } else {
        return null;
      }
    }}
  </LoggContext.Consumer>
);

const SideBarManager = (props) => {
  const { navigation } = props;
  const [user] = useContext(UserContext);
  const isCompras =
    user?.groups?.find((element) => element === "COMPRAS") || false;
  return (
    <DrawerContentScrollView
      style={styles.container}
      forceInset={{ top: "always", horizontal: "never" }}
    >
      <Content style={{ backgroundColor: "#fff" }}>
        <UserContext.Consumer>
          {(user) => (
            <ListItem
              button
              onPress={() => {
                navigation.navigate("HomeScreen");
              }}
              icon
              style={styles.links}
            >
              <Icon
                style={{ color: "#ffa726", fontSize: 18, marginRight: 5 }}
                active
                name="person"
              />
              <Text
                style={{
                  color: "#555",
                  fontSize: 18,
                }}
              >
                {user[0]?.email}
              </Text>
            </ListItem>
          )}
        </UserContext.Consumer>
        <ListItem
          button
          onPress={() => {
            navigation.navigate("HomeScreen");
          }}
          icon
          style={styles.links}
        >
          <Icon active name="md-checkmark" style={{ color: "#ffa726" }} />
          <Body style={{ borderColor: "transparent" }}>
            <Text style={{ color: "#555", marginLeft: 15 }}>Aprobaciones</Text>
          </Body>
        </ListItem>

        {/* <ListItem
          button
          onPress={
            () => {
              navigation.navigate('AprovationAutoListScreen')
            }
          }
          icon style={styles.links}
        >
          <Icon active name="md-car" style={{ color: '#ffa726' }} />
          <Body style={{ borderColor: 'transparent' }}>
            <Text style={{ color: '#555', marginLeft: 15 }}>Aprobaciones Auto</Text>
          </Body>
        </ListItem> */}

        {renderReports(navigation)}
        <ListItem
          button
          onPress={() => {
            navigation.navigate("SettingsScreen");
          }}
          icon
          style={styles.links}
        >
          <Icon active name="settings" style={{ color: "#ffa726" }} />
          <Body style={{ borderColor: "transparent" }}>
            <Text style={{ color: "#555", marginLeft: 15 }}>
              Configuraciones
            </Text>
          </Body>
        </ListItem>
      </Content>
    </DrawerContentScrollView>
  );
};

export default SideBarManager;
