import Axios from "axios";
import {
  Button,
  Card,
  CardItem,
  Container,
  Form,
  Icon,
  Input,
  Item,
  Label,
  Picker,
  Right,
  Text,
  Textarea,
  View,
} from "native-base";
import * as React from "react";
import {
  Alert,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  ViewBase,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";
import PreLoader from "../../components/PreLoader";
import { hasNulls } from "../../utils/checkNulls";
import config from "../../utils/config";
import HandleHTTPError from "../../utils/handleErrors";
var numeral = require("numeral");

export default function CreateAutoRequestScreen(props) {
  const { navigation, route } = props;
  const capex = route.params?.capex;
  const [working, setWorking] = React.useState(false);
  const [dataForm, setDataForm] = React.useState({
    capex: capex.id,
    qty_requested: null,
    year: 2021,
    brand: null,
    description: capex.description,
    details: "",
    state_plates: null,
    city: null,
    color_one: "",
    color_two: "",
    color_three: "",
  });
  const states = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de Mexico",
    "Coahuila",
    "Colima",
    "Durango",
    "Estado de Mexico",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Michoacan",
    "Morelos",
    "Nayarit",
    "Nuevo Leon",
    "Oaxaca",
    "Puebla",
    "Queretaro",
    "Quintana Roo",
    "San Luis Potosi",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatan",
    "Zacatecas",
  ];
  const [otherBrand, setOtherBrand] = React.useState(false);
  const [availableBudget, setAvailableBudget] = React.useState(
    capex.budget -
      (capex.current_budget > 0
        ? capex.current_budget
        : capex.current_budget * -1)
  );

  const handleChange = (val) => {
    const data = { ...dataForm, ...val };
    setDataForm(data);
  };

  const calculateAvailable = (val) => {
    const qty = parseInt(val);
    if (isNaN(qty)) {
      const available = 0;
      handleChange({ qty_requested: null });
      setAvailableBudget(available);
    } else {
      const available = capex.budget - qty;
      console.log(available);
      setAvailableBudget(available);
    }
  };

  const makeRequest = () => {
    const uri = `${config.api.host}requisitions/auto`;
    setWorking(true);
    if(dataForm.budget_requested < 0){
      if(Platform.OS === 'web'){
        alert('La cantidad solicitada no debe ser menor a 0');
      } else {
        Alert.alert('La cantidad solicitada no debe ser menor a 0');
      }
      setWorking(false)
      return;
    }
    Axios.post(uri, dataForm, {
      headers: config.api.headers,
    })
      .then((response) => {
        setWorking(false);
        if (Platform.OS === "web") {
          alert(
            "Solicitud enviada correctamente",
            " La solicitud entró en proceso de aprobación"
          );
        } else {
          Alert.alert(
            "Solicitud enviada correctamente",
            " La solicitud entró en proceso de aprobación"
          );
        }
        navigation.navigate("HomeScreen");
      })
      .catch((error) => {
        HandleHTTPError(error, navigation);
        setWorking(false);
      });
  };

  if (working) {
    return <PreLoader />;
  }

const statesPicker = states.map((element) =>  <Picker.Item label={element} value={element} key={element} />)

  if (Platform.OS === "web") {
    return (
      <Container style={styles.container}>
        <SafeAreaView style={styles.content}>
          <KeyboardAvoidingView style={{ flex: 1 }}>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <ScrollView
                contentContainerStyle={{ justifyContent: "space-around" }}
              >
                <Form style={{ width: "100%" }}>
                  <View>
                    <CardItem>
                      <Text
                        style={{ fontWeight: "bold" }}
                      >{`AUTO: ${capex.name}`}</Text>
                    </CardItem>
                    <CardItem>
                      <Item
                        stackedLabel
                        error={dataForm.year.length < 4}
                        style={{ width: "50%", height: 50 }}
                      >
                        <Label style={{ fontWeight: "bold" }}>Modelo: </Label>
                        <Input
                          maxLength={4}
                          keyboardType="number-pad"
                          defaultValue={`${dataForm.year}`}
                          numberOfLines={1}
                          onChangeText={(text) => handleChange({ year: text })}
                        />
                      </Item>
                      <Right>
                        <Text>
                          {`"Q" Proyecto: `}
                          <Text style={{ color: "#94bf8d" }}>{capex.q}</Text>
                        </Text>
                      </Right>
                    </CardItem>
                  </View>
                  <Item stackedLabel>
                    <Label style={{ fontWeight: "bold" }}>Descripción: </Label>
                    <Input
                      defaultValue={capex.description}
                      numberOfLines={4}
                      onChangeText={(text) =>
                        handleChange({ description: text })
                      }
                    />
                  </Item>
                  <Item>
                    <Label style={{ fontWeight: "bold" }}>Marca: </Label>
                    <Picker
                      note
                      mode="dropdown"
                      style={{ width: "70%", height: 50 }}
                      selectedValue={dataForm.brand}
                      onValueChange={(item) => {
                        if (item === "other") {
                          setOtherBrand(true);
                          handleChange({ brand: item });
                        } else {
                          setOtherBrand(false);
                          handleChange({ brand: item });
                        }
                      }}
                    >
                      <Picker.Item label="Selecciona un valor" value={null} />
                      <Picker.Item label="Volkswagen" value="Volkswagen" />
                      <Picker.Item label="Toyota" value="Toyota" />
                      <Picker.Item label="Audi" value="Audi" />
                      <Picker.Item label="Peugeot" value="Peugeot" />
                      <Picker.Item label="Mazda" value="Mazda" />
                      <Picker.Item label="Nissan" value="Nissan" />
                      <Picker.Item label="Kia" value="Kia" />
                      <Picker.Item label="Suzuki" value="Suzuki" />
                      <Picker.Item label="Otro" value="other" />
                    </Picker>
                  </Item>
                  {otherBrand && (
                    <Item>
                      <Label style={{ fontWeight: "bold" }}>
                        Escribe la marca:{" "}
                      </Label>
                      <Input
                        autoFocus={otherBrand}
                        onChangeText={(text) => handleChange({ brand: text })}
                      />
                    </Item>
                  )}
                  <Item>
                    <Label style={{ fontWeight: "bold" }}>
                      Estado de emplacamiento:{" "}
                    </Label>
                    <Picker
                      note
                      mode="dropdown"
                      style={{ width: "40%", height: 50 }}
                      selectedValue={dataForm.state_plates}
                      onValueChange={(item) =>
                        handleChange({ state_plates: item })
                      }
                    >
                      <Picker.Item label="Selecciona un valor" value={null} />
                      {statesPicker}
                    </Picker>
                  </Item>
                  <Item stackedLabel>
                    <Label style={{ fontWeight: "bold" }}>
                      Ciudad de entrega:{" "}
                    </Label>
                    <Input
                      numberOfLines={4}
                      onChangeText={(text) => handleChange({ city: text })}
                    />
                  </Item>
                  <Item stackedLabel>
                    <Label style={{ fontWeight: "bold" }}>
                      Color (1ra Opción):{" "}
                    </Label>
                    <Input
                      placeholder=""
                      numberOfLines={4}
                      onChangeText={(text) => handleChange({ color_one: text })}
                    />
                  </Item>
                  <Item stackedLabel>
                    <Label style={{ fontWeight: "bold" }}>
                      Color (2ra Opción):{" "}
                    </Label>
                    <Input
                      placeholder=""
                      numberOfLines={4}
                      onChangeText={(text) => handleChange({ color_two: text })}
                    />
                  </Item>
                  <Item stackedLabel>
                    <Label style={{ fontWeight: "bold" }}>
                      Color (3ra Opción):{" "}
                    </Label>
                    <Input
                      placeholder=""
                      numberOfLines={4}
                      onChangeText={(text) =>
                        handleChange({ color_three: text })
                      }
                    />
                  </Item>
                  <Item>
                    <Label style={{ fontWeight: "bold" }}>
                      Total presupuesto: $
                    </Label>
                    <Input
                      placeholder=""
                      value={capex.budget}
                      editable={false}
                      keyboardType="number-pad"
                      // onChangeText={(text) => handleChange({ budget_requested: text })}
                      // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                    />
                  </Item>
                  <Item>
                    <Label style={{ fontWeight: "bold" }}>
                      Presupuesto restante: $
                    </Label>
                    <Input
                      placeholder=""
                      value={capex.current_budget}
                      editable={false}
                      keyboardType="number-pad"
                      onChangeText={(text) =>
                        handleChange({ budget_requested: text })
                      }
                      onEndEditing={() =>
                        calculateAvailable(dataForm.budget_requested)
                      }
                    />
                  </Item>
                  <Item>
                    <Label style={{ fontWeight: "bold" }}>
                      Consumo total: $
                    </Label>
                    <Input
                      placeholder=""
                      value={`${availableBudget}`}
                      editable={false}
                      keyboardType="number-pad"
                      onChangeText={(text) =>
                        handleChange({ budget_requested: text })
                      }
                      onEndEditing={() =>
                        calculateAvailable(dataForm.budget_requested)
                      }
                    />
                  </Item>
                  <Item stackedLabel>
                    <Label style={{ fontWeight: "bold" }}>
                      Cantidad solicitada: $
                    </Label>
                    <Input
                      placeholder=""
                      keyboardType="number-pad"
                      value={dataForm.qty_requested}
                      onChangeText={(text) => {
                        const qty = parseInt(text);
                        if (isNaN(qty)) {
                          handleChange({ qty_requested: null });
                        } else {
                          handleChange({ qty_requested: text });
                        }
                      }}
                      onSubmitEditing={() =>
                        calculateAvailable(dataForm.qty_requested)
                      }
                      onKeyPress={({ nativeEvent }) => {
                        if (nativeEvent.key === "Tab") {
                          calculateAvailable(dataForm.qty_requested);
                        }
                      }}
                    />
                  </Item>
                  <Item stackedLabel>
                    <Label style={{ fontWeight: "bold" }}>Detalles: </Label>
                    <Input
                      placeholder=""
                      numberOfLines={5}
                      onFocus={() => calculateAvailable(dataForm.qty_requested)}
                      onChangeText={(text) => handleChange({ details: text })}
                    />
                  </Item>
                </Form>
              </ScrollView>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
          <Button
            large
            style={{ alignSelf: "center", marginTop: "10%" }}
            info
            disabled={hasNulls(dataForm)}
            onPress={() => makeRequest()}
          >
            <Icon name="paper-plane-outline" />
            <Text>Enviar</Text>
          </Button>
        </SafeAreaView>
      </Container>
    );
  }

  return (
    <Container style={styles.container}>
      <SafeAreaView style={styles.content}>
        <KeyboardAvoidingView style={{ flex: 1 }}>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <ScrollView
              contentContainerStyle={{ justifyContent: "space-around" }}
            >
              <Form style={{ width: "100%" }}>
                <View>
                  <CardItem>
                    <Text
                      style={{ fontWeight: "bold" }}
                    >{`AUTO: ${capex.name}`}</Text>
                  </CardItem>
                  <CardItem>
                    <Item
                      error={dataForm.year.length < 4}
                      style={{ width: "50%", height: 50 }}
                    >
                      <Label>Modelo: </Label>
                      <Input
                        maxLength={4}
                        keyboardType="number-pad"
                        defaultValue={`${dataForm.year}`}
                        numberOfLines={1}
                        onChangeText={(text) => handleChange({ year: text })}
                      />
                    </Item>
                    <Right>
                      <Text>
                        {`"Q" Proyecto: `}
                        <Text style={{ color: "#94bf8d" }}>{capex.q}</Text>
                      </Text>
                    </Right>
                  </CardItem>
                </View>
                <Item>
                  <Label>Descripción: </Label>
                  <Input
                    defaultValue={capex.description}
                    numberOfLines={4}
                    onChangeText={(text) => handleChange({ description: text })}
                  />
                </Item>
                <Item>
                  <Label>Marca: </Label>
                  <Picker
                    note
                    mode="dropdown"
                    style={{ width: "70%", height: 50 }}
                    selectedValue={dataForm.brand}
                    onValueChange={(item) => {
                      if (item === "other") {
                        setOtherBrand(true);
                        handleChange({ brand: item });
                      } else {
                        setOtherBrand(false);
                        handleChange({ brand: item });
                      }
                    }}
                  >
                    <Picker.Item label="Selecciona un valor" value={null} />
                    <Picker.Item label="Volkswagen" value="Volkswagen" />
                    <Picker.Item label="Toyota" value="Toyota" />
                    <Picker.Item label="Audi" value="Audi" />
                    <Picker.Item label="Peugeot" value="Peugeot" />
                    <Picker.Item label="Mazda" value="Mazda" />
                    <Picker.Item label="Nissan" value="Nissan" />
                    <Picker.Item label="Kia" value="Kia" />
                    <Picker.Item label="Suzuki" value="Suzuki" />
                    <Picker.Item label="Otro" value="other" />
                  </Picker>
                </Item>
                {otherBrand && (
                  <Item>
                    <Label>Escribe la marca: </Label>
                    <Input
                      autoFocus={otherBrand}
                      onChangeText={(text) => handleChange({ brand: text })}
                    />
                  </Item>
                )}
                <Item>
                  <Label>Estado de emplacamiento: </Label>
                  <Picker
                    note
                    mode="dropdown"
                    style={{ width: "40%", height: 50 }}
                    selectedValue={dataForm.state_plates}
                    onValueChange={(item) =>
                      handleChange({ state_plates: item })
                    }
                  >
                    <Picker.Item label="Selecciona un valor" value={null} />
                    {statesPicker}
                  </Picker>
                </Item>
                <Item>
                  <Label>Ciudad de entrega: </Label>
                  <Input
                    numberOfLines={4}
                    onChangeText={(text) => handleChange({ city: text })}
                  />
                </Item>
                <Item>
                  <Label>Color (1ra Opción): </Label>
                  <Input
                    placeholder=""
                    numberOfLines={4}
                    onChangeText={(text) => handleChange({ color_one: text })}
                  />
                </Item>
                <Item>
                  <Label>Color (2ra Opción): </Label>
                  <Input
                    placeholder=""
                    numberOfLines={4}
                    onChangeText={(text) => handleChange({ color_two: text })}
                  />
                </Item>
                <Item>
                  <Label>Color (3ra Opción): </Label>
                  <Input
                    placeholder=""
                    numberOfLines={4}
                    onChangeText={(text) => handleChange({ color_three: text })}
                  />
                </Item>
                <Item>
                  <Label>Total presupuesto: $</Label>
                  <Input
                    placeholder=""
                    value={capex.budget}
                    editable={false}
                    keyboardType="number-pad"
                    // onChangeText={(text) => handleChange({ budget_requested: text })}
                    // onEndEditing={() => calculateAvailable(dataForm.budget_requested)}
                  />
                </Item>
                <Item>
                  <Label>Consumo total: $</Label>
                  <Input
                    placeholder=""
                    value={capex.current_budget}
                    editable={false}
                    keyboardType="number-pad"
                    onChangeText={(text) =>
                      handleChange({ budget_requested: text })
                    }
                    onEndEditing={() =>
                      calculateAvailable(dataForm.budget_requested)
                    }
                  />
                </Item>
                <Item>
                  <Label>Presupuesto restante: $</Label>
                  <Input
                    placeholder=""
                    defaultValue={`${availableBudget}`}
                    editable={false}
                    keyboardType="number-pad"
                    onChangeText={(text) =>
                      handleChange({ budget_requested: text })
                    }
                    onEndEditing={() =>
                      calculateAvailable(dataForm.budget_requested)
                    }
                  />
                </Item>
                <Item>
                  <Label>Cantidad solicitada: $</Label>
                  <Input
                    placeholder=""
                    keyboardType="number-pad"
                    onChangeText={(text) =>
                      handleChange({ qty_requested: text })
                    }
                    onSubmitEditing={() =>
                      calculateAvailable(dataForm.qty_requested)
                    }
                  />
                </Item>
                <Item>
                  <Label>Detalles: </Label>
                  <Input
                    placeholder=""
                    numberOfLines={5}
                    onChangeText={(text) => handleChange({ details: text })}
                    onEndEditing={() => calculateAvailable(dataForm.details)}
                  />
                </Item>
              </Form>
              <Button
                block
                info
                disabled={hasNulls(dataForm)}
                onPress={() => makeRequest()}
              >
                <Text>Enviar</Text>
              </Button>
            </ScrollView>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 10,
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
    }),
  },
});
