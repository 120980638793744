import * as React from 'react';
import { Body, Container, Icon, Left, ListItem, Right, Text, View } from 'native-base';
import config from '../../utils/config';
import Axios from 'axios';
import { SafeAreaView } from 'react-native-safe-area-context';
import { FlatList } from 'react-native-gesture-handler';
import PreLoader from '../../components/PreLoader';
import HandleHTTPError from '../../utils/handleErrors';
import { Platform, StyleSheet, TextInput } from 'react-native';
import { SearchBar } from 'react-native-elements';

var numeral = require('numeral');

export default function AprovationAutoListScreen(props) {
    const { navigation } = props;
    const [working, setWorking] = React.useState(false);
    const [dataList, setDataList] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [searchTerm, setSearchTerm] = React.useState('');

    React.useEffect(() => {
        setWorking(true);
        const uri = `${config.api.host}requisitions/auto`
        Axios.get(uri, {
            headers: config.api.headers,
            params: {
                search,
            }
        })
            .then(({ data }) => {
              console.log(data.results);
                setDataList(data.results);
                setWorking(false);
            })
            .catch((error) => {
                if (Platform.OS === 'web') {
                    alert(`Ha ocurrido un error ${JSON.stringify(error.response)}`);
                } else {
                    HandleHTTPError(error, navigation);
                }

                setWorking(false);
            })
    }, [search]);


    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    const handleSubmit = () => {
        setSearch(searchTerm);
    };

    const renderLastAprove = (element) => {
        if (element.approved_by) {
          return <Text>Aprobada por: {element.approved_by.full_name}</Text>
        } else {
          if (element.rejected_by) {
            return <Text>No aprobada por: {element.rejected_by.full_name}</Text>
          }
        }
        return null;
      }

    const renderStatus = (status) => {
        if (status === 0) {
            return <Text style={{ color: '#007bff' }}>En proceso</Text>
        }
        if (status === 1) {
            return <Text style={{ color: '#28a745' }}>Aprobada</Text>
        }
        if (status === 2) {
            return <Text style={{ color: '#dc3545' }}>Rechazada</Text>
        }
        if (status === 3) {
            return <Text style={{ color: '#dc3545' }}>Cancelada</Text>
        }

    }

    const renderKind = (kind) => {
        if (kind === 'capex') {
            return <Text style={{ color: '#007bff' }}>CAPEX</Text>
        }
        if (kind === 'sub') {
            return <Text style={{ color: '#6c757d' }}>Subdivisión</Text>
        }
        if (kind === 'change') {
            return <Text style={{ color: '#28a745' }}>Cambio</Text>
        }
        return <Text style={{ color: '#dc3545' }}>AUTO</Text>

    }

    const getNextScreen = (kind) => {
            return 'AproveAutoScreen'
      }

    const renderListItem = (element) => {
        const route = getNextScreen(element.kind)
        if (Platform.OS === 'web') {
          return (
            <ListItem button onPress={() => navigation.navigate(route, {
              item: element.id
            })}>
              <Body>
                <Text>{element.name} {renderKind(element.kind)}</Text>
                <Text>Solicitante: {element.requested_by?.full_name}</Text>
                <Text>Descripción: {element.description}</Text>
                {/* <Text>Objetivo: {element.objective}</Text> */}
    
              </Body>
              <View>
                <Text >Monto solicitado:
                  <Text style={{ fontWeight: 'bold', color: 'green' }}>
                    {numeral(element.budget_requested).format('000,000.00')}
                  </Text>
                </Text>
                {renderStatus(element.status)}
                {renderLastAprove(element)}
              </View>
            </ListItem>
          )
        }
        return (
          <ListItem button onPress={() => {
            console.log(element);
            navigation.navigate(route, {
              item: element.id
            })
          }}>
            <Body>
              <Text>{element.name} {renderKind(element.kind)}</Text>
              <Text>Solicitante: {element.requested_by?.full_name}</Text>
              <Text >Monto solicitado:
                <Text style={{ fontWeight: 'bold', color: 'green' }}>
                  {numeral(element.budget_requested).format('000,000.00')}
                </Text>
              </Text>
              <Text>Descripción: {element.description}</Text>
              {renderStatus(element.status, element)}
              {renderLastAprove(element)}
            </Body>
          </ListItem>
        )
      }
    if (working) {
        return <PreLoader />;
    }

    return (
        <Container style={styles.container}>
            <SafeAreaView style={styles.content}>
                <SearchBar
                    platform={Platform.OS}
                    lightTheme
                    placeholder="Buscar..."
                    onChangeText={(searchString) => { handleSearch(searchString); }}
                    value={searchTerm}
                    onSubmitEditing={handleSubmit}
                />
                <FlatList
                    data={dataList}
                    renderItem={({ item }) => renderListItem(item)}
                    ListEmptyComponent={<ListItem>
                        <Body>
                            <Text>Sin solicitudes para mostrar</Text>
                        </Body>

                    </ListItem>}
                    keyExtractor={(item) => item.id}
                />
            </SafeAreaView>
        </Container>
    )
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: '#fff',

    },
    content: {
        flex: 1, padding: 10,
        ...Platform.OS === 'web' && {
            // maxWidth: '70%',
            width: '50%',
            alignSelf: 'center'
        }
    },
    searchSection: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    searchIcon: {
        padding: 10,
    },
    input: {
        flex: 1,
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        backgroundColor: '#fff',
        color: '#424242',
        borderWidth: 1,
        borderColor: '#eee',
    },
});