import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import HomeScreen from "../screens/HomeScreen";
import { navigationOptions } from "../constants/Navigation";
import LogoTitle from "../components/LogoTitle";
import { DrawerToggleButton } from "@react-navigation/drawer";
import SelectCapexScreen from "../screens/Capex/SelectCapex";
import CreateCapexRequestScreen from "../screens/Capex/CreateCapexRequest";
import RequestListScreen from "../screens/requestLists/index";
import HomeScreenAprovations from "../screens/aprovations";
import AproveCapexScreen from "../screens/aprovations/AproveCapex";
import SelectCapexCambioScreen from "../screens/Cambio/SelectCapexCambio";
import AproveCambioScreen from "../screens/aprovations/AproveCambio";
import LogoutScreen from "../screens/LogoutScreen";
import SelectCapexSubScreen from "../screens/Subdivision/SelectCapexSub";
import CreateSubRequestScreen from "../screens/Subdivision/CreateSubRequest";
import AproveSubScreen from "../screens/aprovations/AproveSub";
import SettingsScreen from "../screens/SettingsScreen";
import CreateAutoRequestScreen from "../screens/Auto/CreateAutoRequest";
import SelectCapexAutoScreen from "../screens/Auto/SelectCapexAuto";
import AprovationAutoListScreen from "../screens/aprovations/AprovationAutoList";
import AproveAutoScreen from "../screens/aprovations/AproveAuto";
import RequestListAutoScreen from "../screens/requestLists/RequestListAutoScreen";
import ReportsScreen from "../screens/reports";
import RequestDetailScreen from "../screens/requestLists/requestListDetail";
import BudgetLimitScreen from "../screens/budgetLimit";

const Stack = createStackNavigator();

const HomeStack = ({ navigation, route }) => {
  return (
    <Stack.Navigator screenOptions={{ ...navigationOptions }}>
      <Stack.Screen
        name="HomeScreen"
        options={({ navigation }) => ({
          title: "CAPEX",
          headerTitle: (props) => <LogoTitle {...props} />,
          headerLeft: () => (
            <DrawerToggleButton
              tintColor="#fff"
              onPress={() => navigation.toggleDrawer()}
            />
          ),
        })}
        component={HomeScreen}
      />
      <Stack.Screen
        name="SelectCapexScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Solicitud CAPEX"} />
          ),
        }}
        component={SelectCapexScreen}
      />
      <Stack.Screen
        name="CreateCapexRequestScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Solicitud CAPEX"} />
          ),
        }}
        component={CreateCapexRequestScreen}
      />
      <Stack.Screen
        name="SelectCapexCambioScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Solicitud Cambio"} />
          ),
        }}
        component={SelectCapexCambioScreen}
      />
      <Stack.Screen
        name="RequestListScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Mis estatus"} />
          ),
        }}
        component={RequestListScreen}
      />
      <Stack.Screen
        name="RequestListAutoScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Mis estatus AUTO"} />
          ),
        }}
        component={RequestListAutoScreen}
      />
      <Stack.Screen
        name="RequestDetailScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Mis estatus"} />
          ),
        }}
        component={RequestDetailScreen}
      />
      <Stack.Screen
        name="SelectCapexSubScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Solicitud Subdivisión"} />
          ),
        }}
        component={SelectCapexSubScreen}
      />
      <Stack.Screen
        name="CreateSubRequestScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Solicitud Subdivisión"} />
          ),
        }}
        component={CreateSubRequestScreen}
      />
      <Stack.Screen
        name="SelectCapexAutoScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Solicitud Auto"} />
          ),
        }}
        component={SelectCapexAutoScreen}
      />
      <Stack.Screen
        name="CreateAutoRequestScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Solicitud Auto"} />
          ),
        }}
        component={CreateAutoRequestScreen}
      />
      <Stack.Screen
        name="SettingsScreen"
        component={SettingsScreen}
        options={{
          title: "Configuración",
        }}
      />
      <Stack.Screen
        name="LogoutScreen"
        options={{
          headerShown: false,
          title: "CAPEX",
        }}
        component={LogoutScreen}
      />
    </Stack.Navigator>
  );
};

export const AprovationsStack = ({ navigation, route }) => {
  return (
    <Stack.Navigator
      initialRouteName="HomeScreen"
      screenOptions={{ ...navigationOptions }}
    >
      <Stack.Screen
        name="HomeScreen"
        options={({ navigation }) => ({
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Solicitudes"} />
          ),
          headerLeft: () => (
            <DrawerToggleButton
              tintColor="#fff"
              onPress={() => navigation.toggleDrawer()}
            />
          ),
        })}
        component={HomeScreenAprovations}
      />
      <Stack.Screen
        name="AprovationAutoListScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Solicitudes AUTO"} />
          ),
          headerLeft: () => (
            <DrawerToggleButton
              tintColor="#fff"
              onPress={() => navigation.toggleDrawer()}
            />
          ),
        }}
        component={AprovationAutoListScreen}
      />
      <Stack.Screen
        name="RequestListAutoScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Estatus AUTO"} />
          ),
        }}
        component={RequestListAutoScreen}
      />
      <Stack.Screen
        name="AproveCapexScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => <LogoTitle {...props} title={"CAPEX"} />,
        }}
        component={AproveCapexScreen}
      />
      <Stack.Screen
        name="AproveCambioScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => <LogoTitle {...props} title={"Cambio"} />,
        }}
        component={AproveCambioScreen}
      />
      <Stack.Screen
        name="AproveAutoScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => <LogoTitle {...props} title={"AUTO"} />,
        }}
        component={AproveAutoScreen}
      />
      <Stack.Screen
        name="AproveSubScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Subdivisión"} />
          ),
        }}
        component={AproveSubScreen}
      />
      <Stack.Screen
        name="ReportsScreen"
        options={{
          title: "Reportes",
          headerTitle: (props) => <LogoTitle {...props} title={"Reportes"} />,
          headerLeft: () => (
            <DrawerToggleButton
              tintColor="#fff"
              onPress={() => navigation.toggleDrawer()}
            />
          ),
        }}
        component={ReportsScreen}
      />
      <Stack.Screen
        name="RequestListScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Estatus de solicitudes"} />
          ),
        }}
        component={RequestListScreen}
      />
       <Stack.Screen
        name="RequestDetailScreen"
        options={{
          title: "CAPEX",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Mis estatus"} />
          ),
        }}
        component={RequestDetailScreen}
      />
      <Stack.Screen
        name="BudgetLimitScreen"
        options={{
          title: "Limites de presupuesto",
          headerTitle: (props) => (
            <LogoTitle {...props} title={"Limites de presupuesto"} />
          ),
          headerLeft: () => (
            <DrawerToggleButton
              tintColor="#fff"
              onPress={() => navigation.toggleDrawer()}
            />
          ),
        }}
        component={BudgetLimitScreen}
      />
      <Stack.Screen
        name="SettingsScreen"
        component={SettingsScreen}
        options={{
          title: "Configuración",
        }}
      />
      <Stack.Screen
        name="LogoutScreen"
        options={{
          headerShown: false,
          title: "CAPEX",
        }}
        component={LogoutScreen}
      />
    </Stack.Navigator>
  );
};

export default HomeStack;
