import Axios from "axios";
import {
  Button,
  Card,
  CardItem,
  Container,
  Form,
  Icon,
  Input,
  Item,
  Label,
  Text,
  Textarea,
  View,
} from "native-base";
import * as React from "react";
import {
  Alert,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  ViewBase,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import PreLoader from "../../components/PreLoader";
import { hasNulls } from "../../utils/checkNulls";
import config from "../../utils/config";
import HandleHTTPError from "../../utils/handleErrors";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";
import uploadFileAsync from "../../utils/uploadFile";

var numeral = require("numeral");

export default function CreateCapexRequestScreen(props) {
  const { navigation, route } = props;
  const capex = route.params?.capex;
  console.log(capex);
  const [working, setWorking] = React.useState(false);
  const [dataForm, setDataForm] = React.useState({
    capex: capex.id,
    budget_requested: null,
    description: capex.description,
    objective: null,
  });
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [differenceBudget] = React.useState(
    parseFloat(capex.budget) -
      (parseFloat(capex.current_budget) > 0
        ? parseFloat(capex.current_budget)
        : parseFloat(capex.current_budget) * -1)
  );
  const [availableBudget, setAvailableBudget] = React.useState(
    parseFloat(capex.current_budget)
  );

  const [usedBudget] = React.useState(
    parseFloat(capex.budget) +
      differenceBudget * -1 -
      (differenceBudget > 0 ? differenceBudget * -1 : 0) -
      availableBudget
  );

  const handleChange = (val) => {
    const data = { ...dataForm, ...val };
    setDataForm(data);
  };

  const calculateAvailable = (val) => {
    const qty = parseFloat(val);
    if (isNaN(qty)) {
      const available = 0;
      setAvailableBudget(available);
    } else {
      const available = parseFloat(capex.current_budget) - qty;
      setAvailableBudget(available);
    }
  };

  const changeFileName = async (reference, kind) => {
    const { pdfFile, xmlFile } = uploadedFiles;
    const dataFile = kind === "pdf" ? pdfFile : xmlFile;
    let extension = dataFile.name.split(".").pop();
    let filename = dataFile.uri.split("/").pop();
    let newUri = dataFile.uri.replace(filename, `${reference}.${extension}`);

    await FileSystem.copyAsync({
      from: dataFile.uri,
      to: newUri,
    })
      .then(async () => {
        await FileSystem.getInfoAsync(newUri)
          .then(async (response) => {
            if (response.exists) {
              const nFile = dataFile;
              nFile.uri = newUri;
              (await kind) === "pdf" ? uploadPDF(nFile) : uploadXML(nFile);
            }
          })
          .catch((error) => {
            console.log("error", error);
            // Alert.alert('Error', `${JSON.stringify(error)}`);
          });
      })
      .catch((error) => {
        console.log("error", error);
        // Alert.alert('Error', `${JSON.stringify(error)}`);
      });
  };

  const uploadFile = (file) => {
    // setUploadedFiles(prev => { return { ...prev, ...{ file: true, dataFile: file } } });
    const index = uploadedFiles.findIndex(
      (element) => element.name === file.name
    );
    if (index === -1) {
      uploadedFiles.push(file);
      setUploadedFiles(uploadedFiles);
      // setWorking(false);
    }
    setWorking(false);
  };

  const removeFile = (file, index) => {
    if (index !== -1) {
      const nData = [...uploadedFiles];
      nData.splice(index, 1);
      setUploadedFiles(nData);
    }
  };

  const selectFile = (kind = null) => {
    const type = kind ? kind : "*/*";
    DocumentPicker.getDocumentAsync({
      type,
    })
      .then((result) => {
        if (result.type !== "cancel") {
          setWorking(true);
          uploadFile(result);
        } else {
          setWorking(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setWorking(false);
        if (Platform.OS === "web") {
          alert(
            "Ha ocurrido un error al seleccionar el archivo",
            JSON.stringify(error)
          );
        } else {
          Alert.alert(
            "Ha ocurrido un error al seleccionar el archivo",
            JSON.stringify(error)
          );
        }
      })
      .finally(() => {
        setWorking(false);
      });
  };

  const makeRequest = () => {
    const uri = `${config.api.host}requisitions/capex`;
    setWorking(true);
    dataForm.files = uploadedFiles.length ?? 0;
    if (dataForm.budget_requested < 0) {
      if (Platform.OS === "web") {
        alert("La cantidad solicitada no debe ser menor a 0");
      } else {
        Alert.alert("La cantidad solicitada no debe ser menor a 0");
      }
      setWorking(false);
      return;
    }
    Axios.post(uri, dataForm, {
      headers: config.api.headers,
    })
      .then((response) => {
        if (uploadedFiles.length > 0) {
          const ref = response.data;
          const promises = uploadedFiles.map((element) =>
            uploadFileAsync(element, `${config.api.host}attachments`, ref.id)
          );
          Promise.all(promises)
            .then(() => {
              setWorking(false);
              if (Platform.OS === "web") {
                alert(
                  "Solicitud enviada correctamente",
                  " La solicitud entró en proceso de aprobación"
                );
              } else {
                Alert.alert(
                  "Solicitud enviada correctamente",
                  " La solicitud entró en proceso de aprobación"
                );
              }
              navigation.navigate("HomeScreen");
            })
            .catch((error) => {
              console.log(error);
              HandleHTTPError(error, navigation);
              setWorking(false);
            });
        } else {
          setWorking(false);
          if (Platform.OS === "web") {
            alert(
              "Solicitud enviada correctamente",
              " La solicitud entró en proceso de aprobación"
            );
          } else {
            Alert.alert(
              "Solicitud enviada correctamente",
              " La solicitud entró en proceso de aprobación"
            );
          }
          navigation.navigate("HomeScreen");
        }
      })
      .catch((error) => {
        HandleHTTPError(error, navigation);
        setWorking(false);
      });
  };

  if (working) {
    return <PreLoader />;
  }

  if (Platform.OS === "web") {
    return (
      <Container style={styles.container}>
        <SafeAreaView style={styles.content}>
          <View style={{ justifyContent: "space-around" }}>
            <Form style={{ width: "100%" }}>
              <View>
                <CardItem>
                  <Text>{`CAPEX: ${capex.name}`}</Text>
                </CardItem>
                <CardItem
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <Text>
                    {`Presupuesto Original: `}
                    <Text style={{ color: "#94bf8d" }}>
                      {numeral(capex.budget).format("000,000.00")}
                    </Text>
                  </Text>
                </CardItem>
                <CardItem
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <Text>
                    {`Presupuesto Usado: `}
                    <Text style={{ color: "#ed6105" }}>
                      {/* {numeral(capex.current_budget).format("000,000.00")} */}
                      {numeral(usedBudget).format("000,000.00")}
                    </Text>
                  </Text>
                </CardItem>
                <CardItem
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <Text>
                    {`Presupuesto Disponible: `}
                    <Text style={{ color: "#94bf8d" }}>
                      {numeral(availableBudget).format("000,000.00")}
                      {/* {numeral(capex.current_budget).format("000,000.00")} */}
                    </Text>
                  </Text>
                </CardItem>
                <CardItem
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <Text>
                    {`Descripción:`}
                    <Text> {dataForm.description}</Text>
                  </Text>
                </CardItem>
              </View>
              <Item stackedLabel bordered>
                <Label>Cantidad Solicitada: </Label>
                <Input
                  keyboardType="number-pad"
                  defaultValue={dataForm.budget_requested}
                  onChangeText={(text) =>
                    handleChange({ budget_requested: text })
                  }
                  onEndEditing={() =>
                    calculateAvailable(dataForm.budget_requested)
                  }
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Tab") {
                      calculateAvailable(dataForm.budget_requested);
                    }
                  }}
                />
              </Item>
              <Item stackedLabel bordered>
                <Label>Objetivo: </Label>
                <Input
                  placeholder=""
                  defaultValue={dataForm.objective}
                  // onFocus={() => calculateAvailable(dataForm.budget_requested)}
                  onChangeText={(text) => handleChange({ objective: text })}
                />
              </Item>
              {uploadedFiles.length < 5 && (
                <CardItem>
                  <Button info onPress={() => selectFile()}>
                    <Text>Adjuntar archivo</Text>
                  </Button>
                </CardItem>
              )}
              {uploadedFiles.length > 0 &&
                uploadedFiles.map((element, index) => {
                  return (
                    <CardItem>
                      <Text
                        numberOfLines={1}
                        ellipsizeMode="tail"
                      >{`Archivo adjunto: ${element.name}`}</Text>
                      <Icon
                        onPress={() => removeFile(element, index)}
                        name="close"
                      />
                    </CardItem>
                  );
                })}
            </Form>
          </View>
          <Button
            style={{ alignSelf: "center", marginTop: "5%" }}
            large
            info
            disabled={hasNulls(dataForm)}
            onPress={() => makeRequest()}
          >
            <Icon name="paper-plane-outline" />
            <Text>Enviar</Text>
          </Button>
        </SafeAreaView>
      </Container>
    );
  }

  return (
    <Container style={styles.container}>
      <SafeAreaView style={styles.content}>
        <KeyboardAvoidingView style={{ flex: 1 }}>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <View style={{ justifyContent: "space-around" }}>
              <Form style={{ width: "100%" }}>
                <View>
                  <CardItem>
                    <Text>{`CAPEX: ${capex.name}`}</Text>
                  </CardItem>
                  <CardItem
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Text>
                      {`Presupuesto Original: `}
                      <Text style={{ color: "#94bf8d" }}>
                        {numeral(capex.budget).format("000,000.00")}
                      </Text>
                    </Text>
                  </CardItem>
                  <CardItem
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Text>
                      {`Presupuesto Usado: `}
                      <Text style={{ color: "#ed6105" }}>
                        {numeral(usedBudget).format("000,000.00")}
                      </Text>
                    </Text>
                  </CardItem>
                  <CardItem
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Text>
                      {`Presupuesto Disponible: `}
                      <Text style={{ color: "#94bf8d" }}>
                        {numeral(availableBudget).format("000,000.00")}
                      </Text>
                    </Text>
                  </CardItem>
                  <CardItem
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Text>
                      {`Descripción:`}
                      <Text> {dataForm.description}</Text>
                    </Text>
                  </CardItem>
                </View>
                <Item>
                  <Label>Cantidad Solicitada</Label>
                  <Input
                    placeholder=""
                    keyboardType="number-pad"
                    onChangeText={(text) =>
                      handleChange({ budget_requested: text })
                    }
                    onEndEditing={() =>
                      calculateAvailable(dataForm.budget_requested)
                    }
                  />
                </Item>
                <Item>
                  <Label>Objetivo</Label>
                  <Input
                    placeholder=""
                    defaultValue={dataForm.objective}
                    // onFocus={() =>
                    //   calculateAvailable(dataForm.budget_requested)
                    // }
                    onChangeText={(text) => handleChange({ objective: text })}
                  />
                </Item>
                {uploadedFiles.length < 5 && (
                  <CardItem>
                    <Button info onPress={() => selectFile()}>
                      <Text>Adjuntar archivo</Text>
                    </Button>
                  </CardItem>
                )}
                {uploadedFiles.length > 0 &&
                  uploadedFiles.map((element, index) => {
                    return (
                      <CardItem>
                        <Text
                          numberOfLines={1}
                          ellipsizeMode="tail"
                        >{`Archivo adjunto: ${element.name}`}</Text>
                        <Icon
                          onPress={() => removeFile(element, index)}
                          name="close"
                        />
                      </CardItem>
                    );
                  })}
              </Form>
            </View>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      </SafeAreaView>
      <Button
        block
        info
        disabled={hasNulls(dataForm)}
        onPress={() => makeRequest()}
      >
        <Text>Enviar</Text>
      </Button>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 10,
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
    }),
  },
});
