import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      LoggedManager: {
        screens: {
          HomeScreen: {
            path: 'home/manager',
          },
        }
      },
      RootManager: {
        screens: {
          HomeScreen: {
            path: 'home/aprove',
          },
          AprovationCapexListScreen: 'home/aprove/capex',
          AproveCapexScreen: {
            path: 'home/capex/aprove/:item',
            parse: {
              item: (id) => `${id}`,
            },
            stringify: {
              item: (id) => id,
            },
          },
          AprovationCambioListScreen: 'home/aprove/cambio',
          AproveCambioScreen: {
            path: 'home/cambio/aprove/:item',
            parse: {
              item: (id) => `${id}`,
            },
            stringify: {
              item: (id) => id,
            },
          },
          AprovationSubListScreen: 'home/aprove/sub',
          AproveSubScreen: {
            path: 'home/sub/aprove/:item',
            parse: {
              item: (id) => `${id}`,
            },
            stringify: {
              item: (id) => id,
            },
          },
          AprovationAutoListScreen: 'home/aprove/auto',
          AproveAutoScreen: {
            path: 'home/auto/aprove/:item',
            parse: {
              item: (id) => `${id}`,
            },
            stringify: {
              item: (id) => id,
            },
          },
          // Logout: 'logout',
        },
      },
      Logged: {
        screens: {
          HomeScreen: {
            path: '/',
          },
        }
      },
      Root: {
        screens: {
          HomeScreen: {
            path: 'home'
          },
          SelectCapexScreen: 'home/capex',
          CreateCapexRequestScreen: {
            path: 'home/capex/:capex',
            parse: {
              capex: ({ id }) => `${id}`,
            },
            stringify: {
              capex: ({ id }) => id,
            },
          },
          SelectCapexCambioScreen: 'home/cambio/create',
          RequestListScreen: {
            path: 'home/request',
          },
          RequestListAutoScreen: 'home/request/auto',
          SelectCapexSubScreen: 'home/sub',
          CreateSubRequestScreen: {
            path: 'home/sub/:capex',
            parse: {
              capex: ({ id }) => `${id}`,
            },
            stringify: {
              capex: ({ id }) => id,
              // capex: ({ id }) => id.replace(/^capex-/, ''),
            },
          },
          SelectCapexAutoScreen: 'home/auto',
          CreateAutoRequestScreen: {
            path: 'home/auto/:capex',
            parse: {
              capex: ({ id }) => `${id}`,
            },
            stringify: {
              capex: ({ id }) => id,
            },
          },
        },
      },
      Guest: {
        screens: {
          SignInScreen: {
            path: 'login',
          },
        }
      },
      AuthLoading: {
        screens: {
          AuthLoadingScreen: {
            path: 'loading',
          },
        }
      },
      Logout: 'logout',
      SettingsScreen: 'config',
      NotFound: '*',
    },
  },
};
