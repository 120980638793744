import { CommonActions, StackActions } from '@react-navigation/native';
import React from 'react';
import {
    ActivityIndicator,
    StatusBar,
    StyleSheet,
    View,
} from 'react-native';
import { LoggContext } from '../stores/Store';
import { loggedValues } from '../utils/enums';
// import { observer } from 'mobx-react/native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
// @observer
function AuthLoadingScreen(props) {
    const { navigation } = props;
    const [logged] = React.useContext(LoggContext);

    const navigate = () => {
        if (logged !== loggedValues.NOLOGGED) {

            if (logged === loggedValues.MANAGER) {
                const resetAction = StackActions.replace('LoggedManager', {

                });
                navigation.dispatch(resetAction);
                // navigation.navigate('LoggedSeller')

            } else if (logged === loggedValues.COMPRAS) {
                const resetAction = StackActions.replace('LoggedManager', {

                });
                navigation.dispatch(resetAction);
            }
            else {
                const resetAction = StackActions.replace('Logged', {

                });
                navigation.dispatch(resetAction);
                // navigation.navigate('Logged', { route: 'DashboardScreen' })
            }

        } else {
            const resetAction = StackActions.replace('Guest');
            navigation.dispatch(resetAction);
            // navigation.navigate('Guest')
        }
    }

    React.useEffect(() => {
        // const unsubscribe = navigation.addListener('focus', () => {
        //     navigate();
        // });
        // return unsubscribe;
        navigate();
    }, [navigation])

    return (
        <View style={styles.container}>
            <ActivityIndicator />
            <StatusBar barStyle="default" />
        </View>
    );
}

export default AuthLoadingScreen;